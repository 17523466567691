// src/components/results/common/ExpandableSection/SectionContent.jsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const SectionContent = ({ isOpen, children }) => (
  <AnimatePresence initial={false}>
    {isOpen && (
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="p-6 border-t border-gray-100">
          {children}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

export default SectionContent;