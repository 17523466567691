import React from 'react';
import {
  LineChart, Line, AreaChart, Area, CartesianGrid,
  XAxis, YAxis, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { Card } from '../../common/Card';

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div className="bg-white p-4 shadow-lg rounded-lg border border-gray-200">
      <p className="font-medium text-gray-900">{`Question ${label}`}</p>
      {payload.map((entry) => (
        <p key={entry.name} className="text-sm" style={{ color: entry.color }}>
          {`${entry.name}: ${entry.value}${entry.name.includes('Difficulty') ? '/5' : 's'}`}
        </p>
      ))}
    </div>
  );
};

const PerformanceCharts = ({ results }) => {
  const getGradeFromResults = () => {
    const firstResult = results[0];
    if (!firstResult?.student_info?.grade) return 'grade_1';
    const grade = firstResult.student_info.grade;
    return `grade_${grade.toString().replace(/\D/g, '')}`;
  };

  const gradeKey = getGradeFromResults();
  
  const chartData = results.map((result, index) => ({
    question: `Q${index + 1}`,
    timeSpent: result.time_taken || 0,
    difficulty: result.question_id?.difficulty_levels?.[gradeKey] || 3,
    performance: result.correct ? 100 : result.student_answer === 'IDK' ? 50 : 0
  }));

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <Card className="p-6 hover:shadow-lg transition-shadow duration-300">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">
          Difficulty Progression
        </h3>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="difficultyGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#EA4B0D" stopOpacity={0.2} />
                  <stop offset="95%" stopColor="#EA4B0D" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
              <XAxis 
                dataKey="question" 
                stroke="#6B7280"
                tick={{ fill: '#6B7280' }}
              />
              <YAxis 
                domain={[0, 5]}
                ticks={[1, 2, 3, 4, 5]} 
                stroke="#6B7280"
                tick={{ fill: '#6B7280' }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Area
                type="monotone"
                dataKey="difficulty"
                stroke="#EA4B0D"
                strokeWidth={2}
                fill="url(#difficultyGradient)"
                name="Difficulty Level"
                activeDot={{ r: 8, fill: '#EA4B0D' }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Card>

      <Card className="p-6 hover:shadow-lg transition-shadow duration-300">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">
          Time Analysis
        </h3>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
              <XAxis 
                dataKey="question" 
                stroke="#6B7280"
                tick={{ fill: '#6B7280' }}
              />
              <YAxis 
                stroke="#6B7280"
                tick={{ fill: '#6B7280' }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line
                type="monotone"
                dataKey="timeSpent"
                stroke="#7F56D9"
                strokeWidth={2}
                name="Time (seconds)"
                dot={{ fill: '#7F56D9', r: 4 }}
                activeDot={{ r: 8, fill: '#7F56D9' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </div>
  );
};

export default PerformanceCharts;