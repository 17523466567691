import React from 'react';
import { motion } from 'framer-motion';
import {
  QuestionMarkCircleIcon,
  BookOpenIcon,
  AcademicCapIcon,
  LightBulbIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';
import MathDisplay from '../../common/MathDisplay';
import ConceptInfo from '../common/ConceptInfo';

const IDKAnalysis = ({ results = [] }) => {
  const idkQuestions = results.filter(q => q.student_answer === 'IDK');

  const conceptGroups = idkQuestions.reduce((groups, question) => {
    const concepts = question.question_id?.concepts?.correct_option || [];
    concepts.forEach(concept => {
      if (!groups[concept]) {
        groups[concept] = [];
      }
      groups[concept].push(question);
    });
    return groups;
  }, {});

  const QuestionCard = ({ question }) => {
    const getGradeDifficulty = () => {
      if (!question.question_id?.difficulty_levels || !question.student_info?.grade) {
        return question.question_id?.difficulty || 3;
      }
      const grade = question.student_info.grade.toString().replace(/\D/g, '');
      return question.question_id.difficulty_levels[`grade_${grade}`] || 3;
    };

    return (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white rounded-lg shadow-sm p-4"
      >
        <div className="flex items-start space-x-3">
          <div className="mt-1 p-2 rounded-full bg-orange-100">
            <QuestionMarkCircleIcon className="w-5 h-5 text-orange-600" />
          </div>
          <div className="flex-1">
            <div className="flex items-center space-x-2">
              <span className="text-xs px-2 py-1 rounded-full bg-purple-100 text-purple-600">
                Level {getGradeDifficulty()}
              </span>
            </div>
            <div className="mt-2 text-gray-800">
              <MathDisplay content={question.question_id?.question_text || ''} />
            </div>

            <div className="mt-3 pt-3 border-t border-gray-100">
              <div>
                <p className="text-sm text-gray-500 mb-2">Correct Answer:</p>
                <div className="p-2 rounded bg-green-50">
                  <p className="text-sm font-medium text-green-600">
                    <MathDisplay 
                      content={question.question_id?.options?.[question.question_id?.correct_answer] || 
                              question.question_id?.correct_answer || 'N/A'} 
                    />
                  </p>
                </div>
              </div>
            </div>

            {question.question_id?.concepts?.correct_option?.length > 0 && (
              <div className="mt-3 pt-3 border-t border-gray-100">
                <p className="text-sm text-gray-500 mb-2">Related Concepts:</p>
                <div className="flex flex-wrap gap-2">
                  {question.question_id.concepts.correct_option
                    .filter(concept => concept !== ',')
                    .map((concept, idx) => (
                      <ConceptInfo key={idx} concept={concept} />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="bg-gradient-to-br from-orange-50 to-yellow-50 rounded-lg p-6">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              Questions Marked "I Don't Know"
            </h3>
            <p className="text-sm text-gray-600 mt-1">
              {idkQuestions.length} questions need your attention
            </p>
          </div>
          <div className="p-3 bg-orange-100 rounded-full">
            <QuestionMarkCircleIcon className="w-6 h-6 text-orange-600" />
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6">
          <div className="bg-white/50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-600">Total IDK</span>
              <QuestionMarkCircleIcon className="w-5 h-5 text-orange-600" />
            </div>
            <p className="text-2xl font-bold text-gray-900">
              {idkQuestions.length}
            </p>
          </div>
          <div className="bg-white/50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-600">Concepts</span>
              <BookOpenIcon className="w-5 h-5 text-purple-600" />
            </div>
            <p className="text-2xl font-bold text-gray-900">
              {Object.keys(conceptGroups).length}
            </p>
          </div>
        </div>
      </div>

      {idkQuestions.length > 0 ? (
        <div className="space-y-4">
          {idkQuestions.map((question, idx) => (
            <QuestionCard key={idx} question={question} />
          ))}
        </div>
      ) : (
        <div className="text-center py-8">
          <AcademicCapIcon className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <p className="text-gray-600">No questions marked as "I Don't Know"</p>
        </div>
      )}
    </div>
  );
};

export default IDKAnalysis;