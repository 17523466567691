// src/components/test/QuestionDisplay/components/QuestionContent/QuestionContent.js
import React from 'react';
import MathDisplay from '../common/MathDisplay';

const QuestionContent = ({ content, questionNumber, totalQuestions }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      {/* Question Number Badge */}
      <div className="flex items-center gap-3 mb-4">
        <div className="flex items-center gap-2">
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-purple-100 text-purple-700 font-semibold text-sm">
            {questionNumber}
          </span>
          <span className="text-gray-500 text-sm">of {totalQuestions}</span>
        </div>
      </div>

      {/* Question Text */}
      <div className="relative">
        <div className="absolute -left-4 top-0 bottom-0 w-1 bg-purple-200 rounded-full" />
        <div className="pl-4">
          <div className="prose prose-lg font-bold max-w-none">
            <MathDisplay content={content || ''} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionContent;