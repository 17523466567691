import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Zap, CheckCircle, XCircle, Clock,
  Lightbulb, AlertTriangle, Trophy, Target
} from 'lucide-react';
import { Tab } from '@headlessui/react';
import MathDisplay from '../../common/MathDisplay';

const GuessingAnalysis = ({ results = [] }) => {
  const guessedQuestions = results.filter(q => q.time_taken <= 3 && q.student_answer !== 'IDK');
  const correctGuesses = guessedQuestions.filter(q => q.correct);
  const incorrectGuesses = guessedQuestions.filter(q => !q.correct);

  const getGradeDifficulty = (question) => {
    if (!question?.question_id?.difficulty_levels || !question?.student_info?.grade) {
      return question?.question_id?.difficulty || 3;
    }
    const grade = question.student_info.grade.toString().replace(/\D/g, '');
    return question.question_id.difficulty_levels[`grade_${grade}`] || 3;
  };

  const StatsCard = ({ title, value, icon: Icon, gradient, iconColor }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.02 }}
      className={`${gradient} rounded-xl p-6 border border-gray-100 shadow-sm`}
    >
      <div className="flex items-start justify-between">
        <div>
          <h3 className="text-sm font-medium text-gray-600 mb-1">{title}</h3>
          <p className="text-3xl font-bold text-gray-900">{value}</p>
        </div>
        <div className={`p-3 rounded-xl ${iconColor} bg-opacity-20`}>
          <Icon className={`w-6 h-6 ${iconColor}`} />
        </div>
      </div>
    </motion.div>
  );

  const QuestionCard = ({ question }) => {
    const isCorrect = question.correct;
    const difficulty = getGradeDifficulty(question);
    
    return (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ scale: 1.01 }}
        className={`bg-white rounded-xl shadow-sm border ${
          isCorrect ? 'border-green-100' : 'border-red-100'
        } overflow-hidden transition-all duration-200`}
      >
        <div className="p-5">
          <div className="flex items-start justify-between">
            <div className="flex items-start space-x-4">
              <div className={`p-2 rounded-xl ${
                isCorrect ? 'bg-green-100' : 'bg-red-100'
              } transform transition-transform group-hover:scale-110`}>
                {isCorrect ? (
                  <Trophy className="w-5 h-5 text-green-600" />
                ) : (
                  <AlertTriangle className="w-5 h-5 text-red-600" />
                )}
              </div>
              <div>
                <div className="flex items-center gap-3 mb-2">
                  <span className="text-xs px-3 py-1 rounded-full bg-purple-100 text-purple-700 font-medium">
                    Level {difficulty}
                  </span>
                  <span className="flex items-center gap-1 text-sm text-gray-500">
                    <Clock className="w-4 h-4" />
                    {question.time_taken.toFixed(1)}s
                  </span>
                </div>
                <div className="text-gray-800">
                  <MathDisplay content={question.question_id?.question_text || ''} />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 pt-4 border-t border-gray-100">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="flex items-center gap-2 mb-2">
                  <Target className="w-4 h-4 text-gray-500" />
                  <p className="text-sm font-medium text-gray-700">Your Answer</p>
                </div>
                <div className={`p-3 rounded-lg ${
                  isCorrect ? 'bg-green-50 border border-green-100' : 'bg-red-50 border border-red-100'
                }`}>
                  <div className={`text-sm font-medium ${
                    isCorrect ? 'text-green-700' : 'text-red-700'
                  }`}>
                    <MathDisplay 
                      content={question.question_id?.options?.[question.student_answer] || 
                              question.student_answer || 'N/A'} 
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center gap-2 mb-2">
                  <Lightbulb className="w-4 h-4 text-gray-500" />
                  <p className="text-sm font-medium text-gray-700">Correct Answer</p>
                </div>
                <div className="p-3 rounded-lg bg-green-50 border border-green-100">
                  <div className="text-sm font-medium text-green-700">
                    <MathDisplay 
                      content={question.question_id?.options?.[question.question_id?.correct_answer] || 
                              question.question_id?.correct_answer || 'N/A'} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  };

  const EmptyState = ({ message }) => (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="text-center py-12 bg-white rounded-xl border border-gray-100 shadow-sm"
    >
      <div className="w-16 h-16 mx-auto mb-4 bg-purple-100 rounded-full flex items-center justify-center">
        <Target className="w-8 h-8 text-purple-600" />
      </div>
      <h3 className="text-lg font-semibold text-gray-900 mb-2">{message}</h3>
      <p className="text-gray-600 max-w-sm mx-auto">
        Keep practicing to improve your speed and accuracy!
      </p>
    </motion.div>
  );

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        <StatsCard
          title="Total Quick Answers"
          value={guessedQuestions.length}
          icon={Zap}
          gradient="bg-gradient-to-br from-purple-50 to-pink-50"
          iconColor="text-purple-600"
        />
        <StatsCard
          title="Correct Guesses"
          value={correctGuesses.length}
          icon={CheckCircle}
          gradient="bg-gradient-to-br from-green-50 to-emerald-50"
          iconColor="text-green-600"
        />
        <StatsCard
          title="Incorrect Guesses"
          value={incorrectGuesses.length}
          icon={XCircle}
          gradient="bg-gradient-to-br from-red-50 to-orange-50"
          iconColor="text-red-600"
        />
      </div>

      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-purple-100/20 p-1">
          {['Correct Guesses', 'Incorrect Guesses'].map((category, idx) => (
            <Tab
              key={category}
              className={({ selected }) =>
                `w-full rounded-lg py-3 text-sm font-medium transition-all duration-200
                ${selected
                  ? 'bg-white text-purple-700 shadow-sm'
                  : 'text-gray-600 hover:bg-white/[0.12] hover:text-purple-600'
                }`
              }
            >
              {category} ({idx === 0 ? correctGuesses.length : incorrectGuesses.length})
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-6">
          <AnimatePresence mode="wait">
            <Tab.Panel className="space-y-4">
              {correctGuesses.length > 0 ? (
                correctGuesses.map((question, idx) => (
                  <QuestionCard key={idx} question={question} />
                ))
              ) : (
                <EmptyState message="No Correct Quick Answers Yet" />
              )}
            </Tab.Panel>
            <Tab.Panel className="space-y-4">
              {incorrectGuesses.length > 0 ? (
                incorrectGuesses.map((question, idx) => (
                  <QuestionCard key={idx} question={question} />
                ))
              ) : (
                <EmptyState message="No Incorrect Quick Answers" />
              )}
            </Tab.Panel>
          </AnimatePresence>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default GuessingAnalysis;