// src/components/test/QuestionDisplay/components/OptionsGrid/OptionsGrid.jsx
import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import MathDisplay from '../common/MathDisplay';

const OptionsGrid = ({ 
  options, 
  selectedAnswer, 
  onSelectAnswer, 
  isDisabled,
  difficulty = 3,
  grade
}) => {
  const [hoveredOption, setHoveredOption] = useState(null);
  const [optionStates, setOptionStates] = useState({});

  // Helper function to normalize grade and get grade level
  const getGradeLevel = useCallback(() => {
    const gradeNum = parseInt(grade?.toString().replace(/\D/g, '') || '0');
    return {
      isJunior: gradeNum <= 6,
      gradeNum
    };
  }, [grade]);

  // Helper function to get difficulty-based styles
  const getDifficultyStyles = useCallback((level) => {
    const { isJunior } = getGradeLevel();
    const styles = {
      1: {
        border: 'border-blue-200',
        hover: 'hover:border-blue-300',
        focus: 'focus:ring-blue-200',
        selectedBg: 'bg-blue-50',
        hoverBg: 'bg-blue-50/50'
      },
      2: {
        border: 'border-green-200',
        hover: 'hover:border-green-300',
        focus: 'focus:ring-green-200',
        selectedBg: 'bg-green-50',
        hoverBg: 'bg-green-50/50'
      },
      3: {
        border: 'border-purple-200',
        hover: 'hover:border-purple-300',
        focus: 'focus:ring-purple-200',
        selectedBg: 'bg-purple-50',
        hoverBg: 'bg-purple-50/50'
      },
      4: {
        border: 'border-orange-200',
        hover: 'hover:border-orange-300',
        focus: 'focus:ring-orange-200',
        selectedBg: 'bg-orange-50',
        hoverBg: 'bg-orange-50/50'
      },
      5: {
        border: 'border-red-200',
        hover: 'hover:border-red-300',
        focus: 'focus:ring-red-200',
        selectedBg: 'bg-red-50',
        hoverBg: 'bg-red-50/50'
      }
    };

    // Adjust colors for junior grades to be less intimidating
    if (isJunior && level >= 4) {
      return styles[level - 1];
    }

    return styles[level] || styles[3];
  }, []);

  // Helper function to get option styles based on state
  const getOptionStyles = useCallback((key) => {
    const isSelected = key === selectedAnswer;
    const isHovered = key === hoveredOption;
    const difficultyStyle = getDifficultyStyles(difficulty);

    let styles = `
      group w-full text-left rounded-xl border-2 transition-all duration-200
      ${isDisabled ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'}
      overflow-hidden focus:outline-none focus:ring-2
    `;

    if (isSelected) {
      styles += ` ${difficultyStyle.border} ${difficultyStyle.selectedBg} ring-2 ${difficultyStyle.focus}`;
    } else {
      styles += ` ${difficultyStyle.border} ${isHovered ? difficultyStyle.hoverBg : ''}`;
    }

    return styles;
  }, [selectedAnswer, hoveredOption, difficulty, isDisabled, getDifficultyStyles]);

  // Helper function to get circle styles
  const getCircleStyles = useCallback((key) => {
    const isSelected = key === selectedAnswer;
    const isHovered = key === hoveredOption;
    const difficultyStyle = getDifficultyStyles(difficulty);

    const baseStyle = "flex-none flex items-center justify-center w-12 h-12 rounded-full transition-all duration-200";

    if (isSelected) {
      return `${baseStyle} bg-purple-600 text-white ring-2 ring-purple-200`;
    }

    if (isHovered) {
      const { isJunior } = getGradeLevel();
      const bgClass = isJunior ? 'bg-purple-100' : difficultyStyle.selectedBg;
      return `${baseStyle} ${bgClass} ${difficultyStyle.hover.replace('border', 'text')}`;
    }

    return `${baseStyle} bg-gray-100 text-gray-600`;
  }, [selectedAnswer, hoveredOption, difficulty, getGradeLevel, getDifficultyStyles]);

  // Handle keyboard navigation
  const handleKeydown = useCallback((event, key) => {
    if (isDisabled) return;
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onSelectAnswer(key);
    }
  }, [isDisabled, onSelectAnswer]);

  return (
    <div className="flex flex-col font-medium gap-3 pb-24 lg:pb-4">
      {Object.entries(options || {}).map(([key, value]) => (
        <button
          key={key}
          onClick={() => !isDisabled && onSelectAnswer(key)}
          onMouseEnter={() => setHoveredOption(key)}
          onMouseLeave={() => setHoveredOption(null)}
          onKeyDown={(e) => handleKeydown(e, key)}
          disabled={isDisabled}
          className={getOptionStyles(key)}
          role="option"
          aria-selected={key === selectedAnswer}
        >
          <div className="flex items-start gap-4 p-4">
            {/* Option Circle (A, B, C, D) */}
            <div className={getCircleStyles(key)}>
              <span className="text-lg font-semibold">{key}</span>
            </div>

            {/* Option Content */}
            <div className="flex-1 flex items-center min-h-[3rem] py-1">
              <div className="text-base sm:text-lg text-gray-800">
                <MathDisplay content={value || ''} />
              </div>
            </div>

            {/* Selected/Hover Indicator */}
            {(key === selectedAnswer || key === hoveredOption) && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                className={`
                  hidden lg:flex items-center
                  transition-opacity duration-200
                  ${key === selectedAnswer ? 'opacity-100' : 'opacity-50'}
                `}
              >
                <svg 
                  className="w-6 h-6 text-purple-600" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M5 13l4 4L19 7" 
                  />
                </svg>
              </motion.div>
            )}
          </div>
        </button>
      ))}

      {/* Spacer for mobile fixed bottom buttons */}
      <div className="h-20 lg:hidden" aria-hidden="true" />
    </div>
  );
};

export default React.memo(OptionsGrid);