import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useResultsData } from '../hooks/useResultsData'
import ResultsContainer from '../components/results/layout/ResultsContainer';
import { LoadingState, ErrorState } from '../components/results/loading';

const ResultsPage = () => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const { data, loading, error } = useResultsData(testId);
  const studentName = sessionStorage.getItem('studentName');

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} onHome={() => navigate('/')} />;
  if (!data?.results?.length) {
    return <ErrorState error="No results found" onHome={() => navigate('/')} />;
  }

  return <ResultsContainer data={data} studentName={studentName} />;
};

export default ResultsPage;