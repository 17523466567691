// src/components/test/QuestionDisplay/components/Progress/ProgressBar.js
import React from 'react';

export const ProgressBar = ({ progress, className = '' }) => {
  return (
    <div className={`h-1.5 bg-gray-100 rounded-full overflow-hidden ${className}`}>
      <div 
        className="h-full bg-purple-600 transition-all duration-300"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

// Export explicitly
export default ProgressBar;