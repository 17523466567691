// client/src/components/results/loading/LoadingState.js
import React from 'react';

const LoadingState = ({ message = 'Processing Your Results...' }) => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center">
    <div className="text-center bg-white rounded-xl p-8 shadow-lg">
      <div className="animate-spin rounded-full h-16 w-16 border-4 border-[#7F56D9] border-t-transparent mx-auto mb-6"></div>
      <h2 className="text-xl text-[#112F3B] font-semibold mb-2">
        {message}
      </h2>
      <p className="text-gray-600">
        Please wait while we analyze your performance...
      </p>
    </div>
  </div>
);

export default LoadingState;