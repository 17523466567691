import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MathDisplay from '../../common/MathDisplay';
import ConceptInfo from '../common/ConceptInfo';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  XCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  BeakerIcon,
  LightBulbIcon,
  BookOpenIcon
} from '@heroicons/react/24/outline';

// MisconceptionCard Component
const MisconceptionCard = ({ item, index, totalQuestions, isOpen, onToggle }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    },
    hover: { 
      scale: 1.005,
      transition: { duration: 0.2 }
    }
  };

  const expandVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: { 
      height: "auto", 
      opacity: 1,
      transition: {
        height: {
          type: "spring",
          stiffness: 100,
          damping: 15
        },
        opacity: { duration: 0.2 }
      }
    },
    exit: { 
      height: 0,
      opacity: 0,
      transition: { duration: 0.2 }
    }
  };

  return (
    <motion.div
      layout
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden
                hover:shadow-md transition-shadow duration-200"
    >
      {/* Header */}
      <button
        onClick={onToggle}
        className="w-full px-6 py-4 flex items-start sm:items-center justify-between 
                   hover:bg-gray-50 transition-colors group"
      >
        <div className="flex items-start gap-4 flex-grow">
          <div className="flex-shrink-0 w-10 h-10 mt-1 rounded-full bg-red-100 
                         flex items-center justify-center transform transition-transform 
                         group-hover:scale-110">
            <ExclamationTriangleIcon className="w-6 h-6 text-red-600" />
          </div>
          <div className="text-left flex-grow">
            <div className="flex flex-wrap items-center gap-2 mb-2">
              <span className="text-sm font-medium text-gray-600">
                Question {item.question_id?.question_id || `${index + 1}`}
              </span>
              <span className="px-3 py-1 text-xs font-medium rounded-full bg-red-100 text-red-600">
                Incorrect
              </span>
              <span className="hidden sm:inline-flex items-center gap-1 text-xs text-gray-500">
                <ClockIcon className="w-3.5 h-3.5" />
                {item.time_taken.toFixed(1)}s
              </span>
            </div>
            <div className="text-gray-900 pr-8">
              <MathDisplay content={item.question_text} />
            </div>
          </div>
        </div>
        <div className={`flex items-center justify-center w-8 h-8 rounded-full 
                        bg-gray-100 transition-all duration-200
                        ${isOpen ? 'bg-purple-100' : 'group-hover:bg-purple-50'}`}>
          {isOpen ? (
            <ChevronUpIcon className="w-5 h-5 text-purple-600" />
          ) : (
            <ChevronDownIcon className="w-5 h-5 text-gray-400 group-hover:text-purple-600" />
          )}
        </div>
      </button>

      {/* Expanded Content */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={expandVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="overflow-hidden"
          >
            <div className="px-6 py-4 border-t border-gray-100">
              {/* Answer Comparison */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                {/* Your Answer */}
                <div className="p-4 bg-red-50 rounded-lg border border-red-100">
                  <div className="flex items-center gap-2 mb-3">
                    <div className="p-1.5 rounded-lg bg-red-100">
                      <XCircleIcon className="w-5 h-5 text-red-600" />
                    </div>
                    <h5 className="text-sm font-medium text-red-800">Your Answer</h5>
                  </div>
                  <div className="space-y-2">
                    <p className="text-red-700 text-sm p-2 bg-red-100/50 rounded-md">
                      Selected: {item.chosen_answer} <MathDisplay content={item.chosen_answer_value} />
                    </p>
                  </div>
                  {/* Updated Misconceptions Display */}
                  {item.concepts_missed?.length > 0 && (
                    <div className="mt-4 pt-3 border-t border-red-200 space-y-3">
                      <div className="flex items-center gap-2">
                        <LightBulbIcon className="w-4 h-4 text-red-600" />
                        <p className="text-sm font-medium text-red-800">Key Misconceptions:</p>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {item.concepts_missed.filter(concept => concept != ',').map((conceptId, idx) => (
                          <ConceptInfo key={idx} concept={conceptId} />
                        ))}
                      </div>
                      <ul className="mt-2 space-y-2">
                        {item.misconceptions && Object.entries(item.misconceptions).map(([key, value], idx) => {
                          if (key === `option_${item.chosen_answer.toLowerCase()}` && value) {
                            return (
                              <li key={idx} className="flex items-start gap-2 text-sm text-red-700 p-2 bg-red-100/50 rounded-md">
                                <BeakerIcon className="w-4 h-4 flex-shrink-0 mt-0.5" />
                                <span>{value}</span>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                {/* Correct Answer */}
                <div className="p-4 bg-green-50 rounded-lg border border-green-100">
                  <div className="flex items-center gap-2 mb-3">
                    <div className="p-1.5 rounded-lg bg-green-100">
                      <CheckCircleIcon className="w-5 h-5 text-green-600" />
                    </div>
                    <h5 className="text-sm font-medium text-green-800">Correct Answer</h5>
                  </div>
                  <p className="text-green-700 text-sm p-2 bg-green-100/50 rounded-md mb-4">
                    Answer: {item.correct_answer} <MathDisplay content={item.correct_answer_value} />
                  </p>
                  {item.concepts_tested?.length > 0 && (
                    <div className="mt-4 pt-3 border-t border-green-200">
                      <div className="flex items-center gap-2 mb-3">
                        <BookOpenIcon className="w-4 h-4 text-green-600" />
                        <p className="text-sm font-medium text-green-800">Related Concepts:</p>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {item.concepts_tested.map((concept, idx) => (
                          <ConceptInfo key={idx} concept={concept} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Time Taken - Mobile View */}
              <div className="sm:hidden flex items-center gap-2 text-sm text-gray-600 mt-2">
                <ClockIcon className="w-4 h-4" />
                <span>Time Taken: {item.time_taken.toFixed(1)}s</span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

/**
 * Misconceptions component displays a list of misconceptions or a message if no misconceptions are found.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Array} [props.misconceptionsData=[]] - An array of misconception data objects.
 * @returns {JSX.Element} A div containing MisconceptionCard components or a "No Misconceptions Found" message.
 */
const Misconceptions = ({ misconceptionsData = [] }) => {
  const [openItems, setOpenItems] = useState(new Set());

    /**
   * Toggles the open/closed state of a misconception item.
   * 
   * @param {number} index - The index of the item to toggle.
   */

  const toggleItem = (index) => {
    const newOpenItems = new Set(openItems);
    if (newOpenItems.has(index)) {
      newOpenItems.delete(index);
    } else {
      newOpenItems.add(index);
    }
    setOpenItems(newOpenItems);
  };

  if (!misconceptionsData.length) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white rounded-xl shadow-sm p-8 text-center"
      >
        <div className="w-16 h-16 mx-auto mb-4 bg-green-100 rounded-full 
                      flex items-center justify-center">
          <CheckCircleIcon className="w-10 h-10 text-green-600" />
        </div>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          No Misconceptions Found
        </h3>
        <p className="text-gray-600 max-w-sm mx-auto">
          Great job! You haven't shown any significant misconceptions in your answers.
        </p>
      </motion.div>
    );
  }

  return (
    <div className="space-y-6">
      {misconceptionsData.map((item, index) => (
        <MisconceptionCard
          key={index}
          item={item}
          index={index}
          totalQuestions={misconceptionsData.length}
          isOpen={openItems.has(index)}
          onToggle={() => toggleItem(index)}
        />
      ))}
    </div>
  );
};

export default Misconceptions;