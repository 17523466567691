// src/components/results/layout/ResultsContainer.jsx
import React from 'react';
import { motion } from 'framer-motion';
import PerformanceHeader from '../performance/PerformanceHeader';
import QuickStats from '../performance/QuickStats';
import ResultsContent from './ResultsContent';
import ResultsFooter from './ResultsFooter';
import ScrollToTop from '../common/ScrollToTop';
import ActionButtons from '../actions/ActionButtons';

const pageTransition = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const ResultsContainer = ({ data, studentName }) => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageTransition}
      className="min-h-screen bg-gray-50"
    >
      <PerformanceHeader
        performance={data.performanceLevel}
        studentName={studentName}
        score={data.summary?.accuracy || 0}
      />

      <motion.main
        variants={containerVariants}
        initial="hidden"
        animate="show"
        className="max-w-7xl mx-auto px-4 py-8"
      >
        <section className="mb-12">
          <QuickStats summary={data.summary} />
        </section>

        <ResultsContent data={data} />
        
        <ActionButtons />
      </motion.main>

      <ResultsFooter studentName={studentName} />
      <ScrollToTop />
    </motion.div>
  );
};

export default ResultsContainer;