import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { TestProvider } from '../contexts/TestContext.jsx';
import HomePage from '../pages/HomePage.jsx';
import InstructionsPage from '../pages/InstructionsPage.jsx';
import TestPage from '../pages/TestPage.jsx';
import ResultsPage from '../pages/ResultsPage.jsx';
import ProtectedRoute from './ProtectedRoute.jsx';

const AppRoutes = () => {
  return (
    <TestProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/instructions" element={<InstructionsPage />} />
          <Route
            path="/test"
            element={
              <ProtectedRoute>
                <TestPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/results/:testId"
            element={
              <ProtectedRoute>
                <ResultsPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </TestProvider>
  );
};

export default AppRoutes;