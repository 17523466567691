// src/components/test/QuestionDisplay/components/Timer/Timer.jsx
import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import TEST_CONFIG from '../../config/test.config';

const Timer = ({ 
  timeRemaining,
  className = '',
  variant = 'default', // 'default', 'compact', or 'minimal'
  grade
}) => {
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
  
  const timerState = useMemo(() => {
    // Adjust warning thresholds based on grade
    const gradeNumber = parseInt(grade?.replace('G', '') || '0');
    const isJuniorGrade = gradeNumber <= 6;
    
    const warningThresholds = {
      critical: isJuniorGrade ? 60 : 30,  // More time for junior grades
      warning: isJuniorGrade ? 120 : 60,
      caution: isJuniorGrade ? 300 : 180
    };

    if (timeRemaining <= warningThresholds.critical) {
      return {
        textColor: 'text-red-600',
        bgColor: 'bg-red-50',
        borderColor: 'border-red-200',
        ringColor: 'ring-red-200',
        pulseColor: 'group-hover:ring-red-300',
        animation: 'animate-pulse',
        icon: 'warning',
        message: 'Time almost up!'
      };
    }
    if (timeRemaining <= warningThresholds.warning) {
      return {
        textColor: 'text-red-600',
        bgColor: 'bg-red-50',
        borderColor: 'border-red-200',
        ringColor: 'ring-red-200',
        pulseColor: 'group-hover:ring-red-300',
        animation: '',
        icon: 'warning',
        message: `Less than ${Math.ceil(timeRemaining / 60)} minutes`
      };
    }
    if (timeRemaining <= warningThresholds.caution) {
      return {
        textColor: 'text-orange-600',
        bgColor: 'bg-orange-50',
        borderColor: 'border-orange-200',
        ringColor: 'ring-orange-200',
        pulseColor: 'group-hover:ring-orange-300',
        animation: '',
        icon: 'clock',
        message: `${Math.ceil(timeRemaining / 60)} minutes left`
      };
    }
    return {
      textColor: 'text-gray-700',
      bgColor: 'bg-gray-50',
      borderColor: 'border-gray-200',
      ringColor: 'ring-gray-200',
      pulseColor: 'group-hover:ring-gray-300',
      animation: '',
      icon: 'clock',
      message: ''
    };
  }, [timeRemaining, grade]);

  const TimerIcon = ({ type }) => {
    if (type === 'warning') {
      return (
        <svg className={`w-5 h-5 ${timerState.textColor}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
      );
    }
    return (
      <svg className={`w-5 h-5 ${timerState.textColor}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    );
  };

  // Compact version (mobile)
  if (variant === 'compact') {
    return (
      <div className={`
        inline-flex items-center gap-2 px-3 py-1.5 
        ${timerState.bgColor} ${timerState.borderColor} 
        border rounded-full ${timerState.animation}
        ${className}
      `}>
        <TimerIcon type={timerState.icon} />
        <span className={`font-mono font-medium ${timerState.textColor}`}>
          {minutes}:{seconds.toString().padStart(2, '0')}
        </span>
      </div>
    );
  }

  // Minimal version (embedded)
  if (variant === 'minimal') {
    return (
      <span className={`
        inline-flex items-center gap-1.5 
        font-mono font-medium ${timerState.textColor} 
        ${timerState.animation} ${className}
      `}>
        <TimerIcon type={timerState.icon} />
        {minutes}:{seconds.toString().padStart(2, '0')}
      </span>
    );
  }

  // Default version (desktop)
  return (
    <div className={`flex flex-col ${className}`}>
      {/* Timer Display */}
      <div className={`
        group relative flex items-center gap-3 px-4 py-2.5
        ${timerState.bgColor} border ${timerState.borderColor}
        rounded-xl transition-all duration-300
        hover:ring-2 ${timerState.ringColor}
        ${timerState.animation}
      `}>
        <TimerIcon type={timerState.icon} />
        
        <div className="flex flex-col">
          <span className={`font-mono text-lg font-bold ${timerState.textColor}`}>
            {minutes}:{seconds.toString().padStart(2, '0')}
          </span>
          {timerState.message && (
            <span className={`text-sm ${timerState.textColor}`}>
              {timerState.message}
            </span>
          )}
        </div>

        {/* Progress Ring */}
        <div className="absolute -right-1 -top-1">
          <svg className="w-2.5 h-2.5">
            <circle
              className={timeRemaining <= 60 ? 'text-red-500' : 'text-gray-300'}
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              r="4"
              cx="5"
              cy="5"
            />
          </svg>
        </div>
      </div>

      {/* Progress Bar */}
      {timeRemaining > 300 && (
        <div className="h-1 bg-gray-100 rounded-full mt-2 overflow-hidden">
          <div 
            className="h-full bg-orange-600 transition-all duration-1000 ease-linear"
            style={{ 
              width: `${(timeRemaining / TEST_CONFIG.TEST_TIME_SECONDS) * 100}%` 
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Timer;