// src/components/test/ActionButtons.js
import React from 'react';

const Button = ({ 
  children, 
  variant = 'secondary', 
  size = 'default',
  isLoading = false, 
  disabled = false,
  onClick,
  type = 'button',
  className = '',
  icon = null
}) => {
  const baseStyles = "relative inline-flex items-center justify-center font-medium transition-all duration-300";
  
  const sizes = {
    small: "px-4 py-2 text-sm rounded-lg",
    default: "px-6 py-3 text-base rounded-lg",
    large: "px-8 py-4 text-lg rounded-xl"
  };

  const variants = {
    primary: `
      bg-purple-600 text-white
      hover:bg-purple-700 active:bg-purple-800
      disabled:bg-purple-300 disabled:cursor-not-allowed
      shadow-lg shadow-purple-200
      focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
    `,
    secondary: `
      bg-gray-100 text-gray-700
      hover:bg-gray-200 active:bg-gray-300
      disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed
      border border-gray-200
      focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2
    `,
    danger: `
      bg-red-600 text-white
      hover:bg-red-700 active:bg-red-800
      disabled:bg-red-300 disabled:cursor-not-allowed
      shadow-lg shadow-red-200
      focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2
    `
  };

  const LoadingSpinner = () => (
    <div className="absolute inset-0 flex items-center justify-center bg-inherit rounded-lg">
      <svg className="animate-spin h-5 w-5 text-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    </div>
  );

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`
        ${baseStyles}
        ${sizes[size]}
        ${variants[variant]}
        ${isLoading ? 'text-transparent' : ''}
        ${className}
      `}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {children}
      {isLoading && <LoadingSpinner />}
    </button>
  );
};

const ActionButtons = ({ 
  onIDK, 
  onSubmit,
  onEndTest, 
  selectedAnswer, 
  isSubmitting = false,
  timeRemaining = 0,
  className = '',
  questionNumber,
  totalQuestions
}) => {
  const isLastQuestion = questionNumber === totalQuestions;
  const isTimeAlmostUp = timeRemaining <= 60;

  // Desktop version
  const DesktopButtons = () => (
    <div className={`hidden lg:flex items-center justify-between ${className}`}>
      <div className="flex items-center gap-4">
        {/* IDK Button */}
        <Button
          variant="secondary"
          onClick={onIDK}
          disabled={isSubmitting}
          icon={
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          }
        >
          I Don't Know
        </Button>

        {/* End Test Button (if not last question) */}
        {/* {!isLastQuestion && (
          <Button
            variant="secondary"
            onClick={onEndTest}
            disabled={isSubmitting}
            icon={
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            }
          >
            End Test
          </Button>
        )} */}
      </div>

      {/* Submit Button */}
      <div className="flex flex-col items-end">
        {isTimeAlmostUp && timeRemaining > 0 && (
          <span className="text-sm text-red-600 mb-2 animate-pulse">
            Time is almost up!
          </span>
        )}
        <Button
          variant="primary"
          size="large"
          onClick={onSubmit}
          disabled={!selectedAnswer || isSubmitting}
          isLoading={isSubmitting}
          icon={
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9 5l7 7-7 7" />
            </svg>
          }
        >
          {isLastQuestion ? 'Finish Test' : 'Next Question'}
        </Button>
      </div>
    </div>
  );

  // Mobile version
  const MobileButtons = () => (
    <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 shadow-lg">
      <div className="flex flex-col gap-3 max-w-xl mx-auto">
        {/* Submit/Next Button */}
        <Button
          variant="primary"
          onClick={onSubmit}
          disabled={!selectedAnswer || isSubmitting}
          isLoading={isSubmitting}
          className="w-full"
          icon={
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9 5l7 7-7 7" />
            </svg>
          }
        >
          {isLastQuestion ? 'Finish Test' : 'Next Question'}
        </Button>

        {/* Action Buttons Row */}
        <div className="flex gap-3">
          <Button
            variant="secondary"
            onClick={onIDK}
            disabled={isSubmitting}
            className="flex-1"
            icon={
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            }
          >
            I Don't Know
          </Button>

          {/* {!isLastQuestion && (
            <Button
              variant="secondary"
              onClick={onEndTest}
              disabled={isSubmitting}
              className="flex-1"
              icon={
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
              }
            >
              End Test
            </Button>
          )} */}
        </div>

        {/* Time Warning */}
        {isTimeAlmostUp && timeRemaining > 0 && (
          <div className="text-sm text-red-600 text-center animate-pulse">
            ⚠️ Time is almost up! {Math.ceil(timeRemaining)} seconds remaining
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <DesktopButtons />
      <MobileButtons />
    </>
  );
};

export default ActionButtons;