import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import TEST_CONFIG from '../config/test.config';
import { GoogleLogin } from '@react-oauth/google';

const benefits = [
  {
    icon: "🎯",
    title: "Smart Adaptation",
    description: "Questions adjust to your skill level"
  },
  {
    icon: "⚡",
    title: "Instant Insights",
    description: "Real-time performance analytics"
  },
  {
    icon: "🧠",
    title: "Deep Analysis",
    description: "Detailed concept mastery tracking"
  },
  {
    icon: "📈",
    title: "Progress Path",
    description: "Visual learning journey tracking"
  }
];

const testFeatures = [
  {
    icon: "⏱️",
    title: "Adaptive Test",
    description: `${TEST_CONFIG.TEST_TIME_MINUTES} minutes duration`
  },
  {
    icon: "🎯",
    title: "Question Format",
    description: "Interactive multiple choice"
  },
  {
    icon: "📊",
    title: "Results",
    description: "Comprehensive analysis report"
  }
];

const AuthSection = ({ googleUserData, renderAuthContent }) => {
  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const [showMobileAuth, setShowMobileAuth] = useState(false);

  const handleGoogleSuccess = (response) => {
    setShowGoogleLogin(false);
    setShowMobileAuth(false);
    renderAuthContent(response);
  };

  const renderAuthButton = () => (
    <motion.button
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      onClick={() => {
        setShowMobileAuth(true);
        setShowGoogleLogin(true);
      }}
      className="w-full bg-gradient-to-r from-[#7F56D9] to-[#EA4B0D] text-white rounded-xl
        py-4 px-6 flex items-center justify-center gap-3 font-medium
        hover:opacity-90 transition-all duration-300 shadow-lg
        hover:shadow-[#7F56D9]/20"
    >
      <span className="text-xl">🚀</span>
      Get Started Now
      <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M13 7l5 5m0 0l-5 5m5-5H6" />
      </svg>
    </motion.button>
  );

  const renderGoogleLogin = () => (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      className="space-y-4"
    >
      <div className="flex justify-center">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={(error) => console.error('Login Failed:', error)}
          useOneTap
          theme="filled_blue"
          size="large"
          text="continue_with"
          shape="circle"
        />
      </div>
      <button
        onClick={() => {
          setShowGoogleLogin(false);
          setShowMobileAuth(false);
        }}
        className="w-full text-white/70 text-sm hover:text-white
          transition-colors duration-200 py-2 flex items-center justify-center gap-2"
      >
        <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        Go back
      </button>
    </motion.div>
  );

  // Mobile bottom sheet component
  const MobileAuthSheet = () => (
    <motion.div
      initial={{ y: '100%' }}
      animate={{ y: 0 }}
      exit={{ y: '100%' }}
      transition={{ type: 'spring', damping: 25 }}
      className="fixed inset-x-0 bottom-0 z-50 bg-white rounded-t-3xl shadow-xl"
    >
      <div className="relative p-6 space-y-6">
        <div className="absolute left-1/2 -translate-x-1/2 -top-3 w-12 h-1.5 bg-gray-300 rounded-full" />
        
        <div className="text-center space-y-2">
          <h3 className="text-xl font-semibold text-gray-900">Begin Your Journey</h3>
          <p className="text-sm text-gray-600">Sign in to start your math adventure</p>
        </div>

        {renderGoogleLogin()}
      </div>
    </motion.div>
  );

  const Backdrop = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={() => {
        setShowMobileAuth(false);
        setShowGoogleLogin(false);
      }}
      className="fixed inset-0 bg-black/60 z-40"
    />
  );

  return (
    <>
      <div className="space-y-6 w-full max-w-md mx-auto pb-24 md:pb-0">
        {/* Main Auth Card - Desktop */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/10 backdrop-blur-lg rounded-xl p-6 lg:p-8 border border-white/20 
            relative overflow-hidden md:block hidden"
        >
          {/* Decorative Elements */}
          <div className="absolute top-0 right-0 w-40 h-40 bg-[#7F56D9]/10 rounded-full blur-3xl -z-10" />
          <div className="absolute bottom-0 left-0 w-40 h-40 bg-[#EA4B0D]/10 rounded-full blur-3xl -z-10" />

          {/* Auth Header */}
          <div className="text-center mb-8">
            <motion.h2 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-2xl lg:text-3xl font-bold text-white mb-3"
            >
              Begin Your Math Journey
            </motion.h2>
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-white/70 text-sm lg:text-base"
            >
              Unlock your mathematical potential with our AI-powered platform
            </motion.p>
          </div>

          {/* Desktop Auth Content */}
          <AnimatePresence mode="wait">
            {!googleUserData && (showGoogleLogin ? renderGoogleLogin() : renderAuthButton())}
            {googleUserData && renderAuthContent()}
          </AnimatePresence>
        </motion.div>

        {/* Mobile Auth Button */}
        <div className="md:hidden fixed bottom-6 inset-x-4 z-30">
          {!googleUserData && !showMobileAuth && renderAuthButton()}
        </div>

        {/* Main content area */}
        {!googleUserData && (
          <>
            {/* Benefits Grid */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="mt-8"
            >
              <h3 className="text-white/90 font-semibold mb-4">
                Why Choose BMI
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {benefits.map((benefit, index) => (
                  <motion.div
                    key={benefit.title}
                    initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.4 + index * 0.1 }}
                    whileHover={{ scale: 1.05 }}
                    className="p-4 bg-white/5 rounded-lg border border-white/10 
                      hover:border-white/20 transition-all cursor-default"
                  >
                    <span className="text-2xl mb-2 block">{benefit.icon}</span>
                    <h4 className="text-white font-medium text-sm">{benefit.title}</h4>
                    <p className="text-white/60 text-xs mt-1">{benefit.description}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Test Preview Card */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10"
            >
              <h3 className="text-white font-semibold mb-4">What to Expect</h3>
              <div className="space-y-4">
                {testFeatures.map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.6 + index * 0.1 }}
                    whileHover={{ x: 5 }}
                    className="flex items-center gap-4 group"
                  >
                    <span className="w-10 h-10 rounded-full bg-[#7F56D9]/20 flex items-center justify-center text-xl
                      group-hover:bg-[#7F56D9]/30 transition-colors">
                      {feature.icon}
                    </span>
                    <div>
                      <h4 className="text-white font-medium text-sm">{feature.title}</h4>
                      <p className="text-white/60 text-xs">{feature.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="text-center space-y-3"
            >
              <div className="flex items-center justify-center gap-2">
                <div className="flex -space-x-2">
                  {[...Array(4)].map((_, i) => (
                    <div
                      key={i}
                      className="w-8 h-8 rounded-full bg-[#7F56D9]/20 border-2 border-white/10
                        flex items-center justify-center text-xs text-white/70"
                    >
                      {['👨‍🎓', '👩‍🎓', '👨‍🏫', '🎓'][i]}
                    </div>
                  ))}
                </div>
                <span className="text-white/70 text-sm ml-2">
                  Join thousands of successful students
                </span>
              </div>
              <p className="text-white/50 text-xs px-4">
                Empowering students globally through AI-powered mathematics assessment
              </p>
            </motion.div>
          </>
        )}
      </div>

      {/* Mobile Auth Sheet */}
      <AnimatePresence>
        {showMobileAuth && !googleUserData && (
          <>
            <Backdrop />
            <MobileAuthSheet />
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default AuthSection;