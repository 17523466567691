// src/components/results/layout/ResultsFooter.jsx
import React from 'react';

const ResultsFooter = ({ studentName }) => (
  <>
    <div className="hidden print:block print-header">
      <h1 className="text-2xl font-bold text-gray-900">Math Test Results</h1>
      <p className="text-gray-600">
        {studentName} - {new Date().toLocaleDateString()}
      </p>
    </div>

    <div className="hidden print:block print-footer">
      <p className="text-gray-500">
        Generated by Math Quotient - {new Date().toLocaleString()}
      </p>
    </div>
  </>
);

export default ResultsFooter;