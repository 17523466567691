import React, { useState, useEffect, useCallback, memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  BookOpen, 
  Sparkles,
  Brain,
  CheckCircle,
  Clock,
  Star,
  ChevronRight,
  X,
  FileText,
  PlayCircle,
  List,
  Zap,
  History,
  BookMarked
} from 'lucide-react';

// Memoized badge component for better performance
const MasteryBadge = memo(({ level }) => {
  const badges = {
    'Mastered': {
      colors: 'bg-green-100 text-green-700 border-green-200',
      Icon: Star,
      animate: true
    },
    'Proficient': {
      colors: 'bg-blue-100 text-blue-700 border-blue-200',
      Icon: CheckCircle
    },
    'Developing': {
      colors: 'bg-yellow-100 text-yellow-700 border-yellow-200',
      Icon: Clock
    },
    'Needs Practice': {
      colors: 'bg-orange-100 text-orange-700 border-orange-200',
      Icon: BookOpen
    }
  };

  const { colors, Icon, animate } = badges[level] || badges['Needs Practice'];

  return (
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full 
        text-xs md:text-sm font-medium border ${colors} 
        ${animate ? 'animate-pulse' : ''}`}
    >
      <Icon className="w-3.5 h-3.5 md:w-4 md:h-4" />
      <span>{level}</span>
    </motion.div>
  );
});

// Tab configuration object
const TABS = {
  overview: {
    icon: FileText,
    label: 'Overview'
  },
  examples: {
    icon: PlayCircle,
    label: 'Examples'
  },
};

// Modal animations
const animations = {
  overlay: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  },
  modal: {
    hidden: { opacity: 0, scale: 0.95, y: 20 },
    visible: { 
      opacity: 1,
      scale: 1,
      y: 0,
      transition: { type: 'spring', bounce: 0.2 }
    },
    exit: { opacity: 0, scale: 0.95, y: 20 }
  }
};

const ConceptInfo = ({ concept }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [conceptDetails, setConceptDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');

  // Memoized fetch function
  const fetchConceptDetails = useCallback(async () => {
    if (typeof concept === 'string' && !conceptDetails && isOpen) {
      setLoading(true);
      try {
        const API_URL = import.meta.env.VITE_API_URL;
        const response = await fetch(`${API_URL}/concepts/${concept}`);
        const result = await response.json();
        setConceptDetails(result.data.concept_details);
      } catch (error) {
        console.error('Error fetching concept details:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [concept, conceptDetails, isOpen]);

  useEffect(() => {
    fetchConceptDetails();
  }, [fetchConceptDetails]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      const handleEscape = (e) => e.key === 'Escape' && setIsOpen(false);
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.body.style.overflow = 'unset';
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [isOpen]);

  const displayDetails = conceptDetails || (typeof concept === 'object' ? concept : null);

  // Memoized content components
  const OverviewContent = memo(() => (
    <div className="p-4 md:p-6 space-y-4 md:space-y-6">
      <div>
        <h5 className="flex items-center gap-2 text-sm font-medium text-gray-900 mb-2">
          <FileText className="w-4 h-4" />
          Description
        </h5>
        <p className="text-sm text-gray-600 leading-relaxed">
          {displayDetails?.description || 'Description not available'}
        </p>
      </div>
      
      {displayDetails?.prerequisites?.length > 0 && (
        <div>
          <h5 className="flex items-center gap-2 text-sm font-medium text-gray-900 mb-2">
            <History className="w-4 h-4" />
            Prerequisites
          </h5>
          <div className="flex flex-wrap gap-2">
            {displayDetails.prerequisites.map((prereq, idx) => (
              <span key={idx} className="inline-flex items-center gap-1.5 px-2.5 py-1 
                rounded-full text-xs font-medium bg-gray-100 text-gray-700
                hover:bg-gray-200 transition-colors">
                <List className="w-3 h-3" />
                {prereq}
              </span>
            ))}
          </div>
        </div>
      )}

      {displayDetails?.keyPoints?.length > 0 && (
        <div>
          <h5 className="flex items-center gap-2 text-sm font-medium text-gray-900 mb-2">
            <Zap className="w-4 h-4" />
            Key Points
          </h5>
          <ul className="space-y-2">
            {displayDetails.keyPoints.map((point, idx) => (
              <li key={idx} className="flex items-start gap-2 text-sm text-gray-600">
                <Brain className="w-4 h-4 flex-shrink-0 mt-0.5 text-purple-500" />
                <span className="leading-relaxed">{point}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  ));

  const PlaceholderContent = memo(({ icon: Icon, title, message }) => (
    <div className="p-4 md:p-6 text-center">
      <div className="p-3 rounded-full bg-purple-100 w-10 h-10 md:w-12 md:h-12 mx-auto mb-3 flex items-center justify-center">
        <Icon className="w-5 h-5 md:w-6 md:h-6 text-purple-600" />
      </div>
      <h5 className="font-medium text-gray-900">{title}</h5>
      <p className="text-sm text-gray-600 mt-2">{message}</p>
    </div>
  ));

  return (
    <div className="relative inline-block">
      <motion.button
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="inline-flex items-center gap-1.5 px-2.5 py-1.5 rounded-full text-sm
          bg-purple-100 text-purple-700 hover:bg-purple-200 
          transition-all focus:outline-none focus:ring-2 focus:ring-purple-400 
          focus:ring-offset-1 border border-purple-200 font-medium
          hover:shadow-sm active:shadow-none"
      >
        <BookOpen className="w-3.5 h-3.5 md:w-4 md:h-4" />
        <span className="truncate max-w-[150px]">
          {displayDetails?.name || `Concept ${typeof concept === 'string' ? concept : ''}`}
        </span>
        <ChevronRight className={`w-3.5 h-3.5 md:w-4 md:h-4 transition-transform duration-300 
          ${isOpen ? 'rotate-90' : ''}`} />
      </motion.button>
      
      <AnimatePresence>
        {isOpen && (
          <div className="fixed inset-0 z-50 px-4 py-6 md:p-6 overflow-y-auto">
            <motion.div
              variants={animations.overlay}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="fixed inset-0 bg-black/30 backdrop-blur-sm"
              onClick={() => setIsOpen(false)}
            />

            <div className="min-h-full flex items-center justify-center">
              <motion.div
                variants={animations.modal}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="w-full max-w-md bg-white rounded-xl shadow-xl border border-purple-100 
                  overflow-hidden relative z-10"
              >
                {loading ? (
                  <div className="p-6 text-center">
                    <div className="inline-block h-10 w-10 relative">
                      <div className="absolute inset-0 rounded-full border-3 border-purple-200"></div>
                      <div className="absolute inset-0 rounded-full border-3 border-purple-600 border-t-transparent animate-spin"></div>
                    </div>
                    <p className="mt-3 text-sm text-gray-600">Loading...</p>
                  </div>
                ) : (
                  <div className="flex flex-col max-h-[80vh] md:max-h-[600px]">
                    <div className="p-4 md:p-6 border-b border-gray-100">
                      <div className="flex justify-between items-start mb-4">
                        <div className="flex items-center gap-3">
                          <div className="p-2 rounded-xl bg-purple-100">
                            <Sparkles className="w-5 h-5 md:w-6 md:h-6 text-purple-600" />
                          </div>
                          <div>
                            <h4 className="text-base md:text-lg font-semibold text-gray-900 break-words">
                              {displayDetails?.name || `Concept ${typeof concept === 'string' ? concept : ''}`}
                            </h4>
                            {displayDetails?.mastery_level && (
                              <div className="mt-2">
                                <MasteryBadge level={displayDetails.mastery_level} />
                              </div>
                            )}
                          </div>
                        </div>
                        <button 
                          onClick={() => setIsOpen(false)}
                          className="p-1 rounded-full hover:bg-gray-100 transition-colors
                            focus:outline-none focus:ring-2 focus:ring-purple-400"
                        >
                          <X className="w-5 h-5 text-gray-500" />
                        </button>
                      </div>

                      <div className="flex gap-2 md:gap-4 overflow-x-auto pb-2">
                        {Object.entries(TABS).map(([key, { icon: Icon, label }]) => (
                          <button
                            key={key}
                            onClick={() => setActiveTab(key)}
                            className={`flex items-center gap-1.5 px-3 py-1.5 text-xs md:text-sm font-medium 
                              rounded-full whitespace-nowrap transition-colors
                              ${activeTab === key 
                                ? 'bg-purple-100 text-purple-700' 
                                : 'text-gray-600 hover:bg-gray-100'}`}
                          >
                            <Icon className="w-3.5 h-3.5 md:w-4 md:h-4" />
                            {label}
                          </button>
                        ))}
                      </div>
                    </div>

                    <div className="flex-1 overflow-y-auto">
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={activeTab}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.2 }}
                        >
                          {activeTab === 'overview' && <OverviewContent />}
                          {activeTab === 'examples' && (
                            <PlaceholderContent
                              icon={PlayCircle}
                              title="Examples Coming Soon!"
                              message="We're working on adding interactive examples for this concept."
                            />
                          )}
                          {activeTab === 'resources' && (
                            <PlaceholderContent
                              icon={BookMarked}
                              title="Additional Resources"
                              message="More learning resources will be available here soon."
                            />
                          )}
                        </motion.div>
                      </AnimatePresence>
                    </div>
                  </div>
                )}
              </motion.div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default memo(ConceptInfo);