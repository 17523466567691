// src/components/results/performance/PerformanceHeader/index.jsx
import React from 'react';
import { motion } from 'framer-motion';
import ReactConfetti from 'react-confetti';
import useWindowSize from '../../../../hooks/useWindowSize';
import { performanceTemplates } from './PerformanceTemplates';

const PerformanceBadge = ({ text, icon: Icon, gradientColors }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    whileHover={{ scale: 1.05 }}
    className={`flex items-center gap-2 px-4 py-2 rounded-full 
      bg-white/10 backdrop-blur-sm border border-white/20
      transition-all duration-300 hover:bg-white/20`}
  >
    <Icon className="w-5 h-5" />
    <span className="text-sm font-medium">{text}</span>
  </motion.div>
);

const ScoreDisplay = ({ score }) => {
  const displayScore = Math.floor(score);

  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ 
        type: "spring",
        stiffness: 260,
        damping: 20 
      }}
      className="relative inline-block"
    >
      <div className="w-40 h-40 rounded-full bg-white/10 backdrop-blur-sm
        border-4 border-white/20 flex items-center justify-center mb-8">
        <div className="text-5xl font-bold">{displayScore}%</div>
      </div>
      
      <div className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-white/20" />
      <div className="absolute -bottom-2 -left-2 w-4 h-4 rounded-full bg-white/20" />
    </motion.div>
  );
};

const PerformanceHeader = ({ performance, studentName, score }) => {
  const windowSize = useWindowSize();
  const template = performanceTemplates[performance];
  const isPerfectScore = score === 100;

  return (
    <div className={`bg-gradient-to-r ${template.gradient} text-white relative overflow-hidden`}>
      {isPerfectScore && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          numberOfPieces={200}
          recycle={false}
          gravity={0.1}
          colors={['#FFD700', '#FFA500', '#FF69B4', '#87CEEB', '#98FB98']}
        />
      )}

      <div className="max-w-7xl mx-auto px-4 py-12 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          {/* Header Content */}
          <div className="mb-8">
            <template.icon className="w-16 h-16 mx-auto mb-6" />
            <h1 className="text-4xl font-bold mb-2">{template.title}</h1>
            <p className="text-xl opacity-90 mb-4">{template.subtitle}</p>
            <p className="text-lg">
              Great work, <span className="font-semibold">{studentName}</span>!
            </p>
          </div>

          {/* Score Display */}
          <ScoreDisplay score={score} />

          {/* Performance Badges */}
          <div className="flex flex-wrap justify-center gap-3 mt-6">
            {template.badges.map((badge, index) => (
              <PerformanceBadge
                key={index}
                text={badge.text}
                icon={badge.icon}
                gradientColors={template.gradient}
              />
            ))}
          </div>

          {/* Message */}
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="mt-8 text-lg text-white/90"
          >
            {template.message}
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

export default PerformanceHeader;