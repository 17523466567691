import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import StudentForm from '../components/StudentForm/StudentForm';
import AuthSection from '../auth/AuthSection';
import TEST_CONFIG from '../config/test.config';

const testTimeMinutes = TEST_CONFIG.TEST_TIME_MINUTES;
const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;

const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/10 hover:border-white/20 transition-colors cursor-pointer"
  >
    <div className="flex items-start gap-4">
      <div className="text-white/90 bg-white/10 p-3 rounded-lg">
        {icon}
      </div>
      <div>
        <h3 className="text-white font-semibold mb-2">{title}</h3>
        <p className="text-white/70 text-sm leading-relaxed">{description}</p>
      </div>
    </div>
  </motion.div>
);

const StatsCard = ({ number, label }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="p-6 bg-white/5 backdrop-blur-sm rounded-xl border border-white/10"
  >
    <h4 className="text-3xl font-bold bg-gradient-to-r from-white to-white/70 bg-clip-text text-transparent">
      {number}
    </h4>
    <p className="text-white/70 text-sm mt-1">{label}</p>
  </motion.div>
);

const features = [
  {
    icon: (
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
      </svg>
    ),
    title: "Adaptive Testing",
    description: "Questions automatically adjust to your performance level, ensuring the right challenge for your skills."
  },
  {
    icon: (
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    ),
    title: "Quick Assessment",
    description: `Complete your test in just ${testTimeMinutes} minutes, with a comprehensive evaluation of your mathematical abilities.`
  },
  {
    icon: (
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
      </svg>
    ),
    title: "Instant Results",
    description: "Get detailed feedback and analysis immediately after completing your assessment."
  },
  {
    icon: (
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
      </svg>
    ),
    title: "Progress Tracking",
    description: "Monitor your improvement over time with detailed personalized performance analytics."
  }
];

const HomePage = () => {
  const navigate = useNavigate();
  const [googleUserData, setGoogleUserData] = useState(null);

const handleFormSuccess = useCallback(() => {
  try {
    const requiredFields = [
      'testId',
      'studentName',
      'studentGrade',
      'studentWhatsapp',
      'studentEmail'
    ];

    const sessionData = {};
    const missingFields = [];

    requiredFields.forEach(field => {
      const value = sessionStorage.getItem(field);
      if (!value) {
        missingFields.push(field);
      }
      sessionData[field] = value;
    });

    if (missingFields.length > 0) {
      console.error('Missing required fields:', missingFields);
      throw new Error(`Missing required information: ${missingFields.join(', ')}`);
    }

    console.log('Navigating with session data:', sessionData);
    navigate('/instructions');
  } catch (error) {
    console.error('Navigation error:', error);
  }
}, [navigate]);

  const renderAuthContent = useCallback((response = null) => {
    if (response) {
      try {
        const decoded = jwtDecode(response.credential);
        const userData = {
          name: decoded.name,
          email: decoded.email,
          picture: decoded.picture,
          sub: decoded.sub
        };
        setGoogleUserData(userData);
        return null;
      } catch (error) {
        console.error('Error decoding Google token:', error);
        return null;
      }
    }

    if (googleUserData) {
      return (
        <StudentForm 
          onSuccess={handleFormSuccess} 
          initialData={googleUserData}
          googleAuthenticated
        />
      );
    }

    return null;
  }, [googleUserData, handleFormSuccess]);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className="min-h-screen bg-gradient-to-b from-[#112F3B] to-[#1a4459] relative overflow-x-hidden">
        <div className="relative min-h-screen flex flex-col">
          {/* Mobile View */}
          <div className="lg:hidden flex flex-col min-h-screen">
            {/* Fixed Logo at Top */}
            <div className="px-4 pt-6">
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center"
              >
                <img 
                  src="/MQ_Logo_Tagline.png" 
                  alt="Math Quotient - Math is easy, Now!" 
                  className="w-64 sm:w-48 h-auto mx-auto"
                />
              </motion.div>
            </div>

            {/* Centered Form Section */}
            <div className="flex-1 flex items-center justify-center px-4">
              <div className="w-full max-w-md">
                {googleUserData ? (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <StudentForm 
                      onSuccess={handleFormSuccess} 
                      initialData={googleUserData}
                      googleAuthenticated
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <AuthSection 
                      googleUserData={googleUserData}
                      renderAuthContent={renderAuthContent}
                    />
                  </motion.div>
                )}
              </div>
            </div>

            {/* Footer */}
            <motion.footer 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6 }}
              className="text-center py-4"
            >
              <p className="text-white/50 text-xs">
                © 2024 Math Quotient. All rights reserved.
              </p>
            </motion.footer>
          </div>

          {/* Desktop View */}
          <div className="hidden lg:block max-w-7xl mx-auto px-4 py-12 w-full">
            <div className="flex items-start justify-between gap-12">
              {/* Left Side - Content */}
              <motion.div 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="flex-1"
              >
                <motion.div className="mb-8">
                  <img 
                    src="/MQ_Logo_Tagline.png" 
                    alt="Math Quotient - Math is easy, Now!" 
                    className="w-80 h-auto"
                  />
                </motion.div>

                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="mb-12"
                >
                  <h1 className="text-4xl font-bold text-white mb-6">
                    Welcome to BMI
                  </h1>
                  <p className="text-xl font-semibold text-white/90 mb-4">
                    Bureau of Math Investigation
                  </p>
                  <p className="text-white/80 text-lg max-w-xl">
                    Experience our AI-powered adaptive testing platform that thoroughly investigates your mathematical abilities. 
                    Like skilled detectives, we uncover your strengths and areas for improvement through comprehensive analysis.
                  </p>
                </motion.div>

                <div className="grid grid-cols-3 gap-6 mb-12">
                  <StatsCard number="1000+" label="Questions" />
                  <StatsCard number="98%" label="Accuracy" />
                  <StatsCard number="1k+" label="Students" />
                </div>

                <div className="grid grid-cols-2 gap-6">
                  {features.map((feature, index) => (
                    <motion.div
                      key={feature.title}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 + index * 0.1 }}
                    >
                      <FeatureCard {...feature} />
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              {/* Right Side - Auth/Form */}
              <motion.div 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="w-full max-w-md lg:sticky lg:top-8"
              >
                <AuthSection 
                  googleUserData={googleUserData}
                  renderAuthContent={renderAuthContent}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default HomePage;