import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ConceptInfo from '../common/ConceptInfo';
import {
  GraduationCap,
  Rocket,
  BookOpen,
  ChevronUp,
  ChevronDown,
  Star,
  Lightbulb,
  TrendingUp,
  Medal,
  Zap
} from 'lucide-react';

/**
 * ConceptAnalysis Component
 * 
 * This component displays an analysis of concepts grouped by mastery levels.
 * It shows expandable sections for each mastery level, containing concept cards.
 * 
 * @param {Object} props - The component props
 * @param {Array} [props.conceptData=[]] - An array of concept objects to be analyzed and displayed
 * @returns {JSX.Element} A React component that renders the concept analysis UI
 */

const ConceptAnalysis = ({ conceptData = [] }) => {
  const [expandedGroup, setExpandedGroup] = useState('Mastered');

  // Rest of the masteryLevelConfig remains the same...
  const masteryLevelConfig = {
    'Mastered': {
      icon: GraduationCap,
      color: 'text-green-600',
      bg: 'bg-green-50',
      border: 'border-green-200',
      iconBg: 'bg-green-100',
      lightBg: 'bg-green-50/50',
      gradient: 'from-green-50 to-green-100/50',
      badge: Star,
      description: 'Excellent understanding of these concepts',
      progress: '90-100%'
    },
    'Proficient': {
      icon: Rocket,
      color: 'text-blue-600',
      bg: 'bg-blue-50',
      border: 'border-blue-200',
      iconBg: 'bg-blue-100',
      lightBg: 'bg-blue-50/50',
      gradient: 'from-blue-50 to-blue-100/50',
      badge: Medal,
      description: 'Good grasp of these concepts',
      progress: '75-89%'
    },
    'Developing': {
      icon: TrendingUp,
      color: 'text-yellow-600',
      bg: 'bg-yellow-50',
      border: 'border-yellow-200',
      iconBg: 'bg-yellow-100',
      lightBg: 'bg-yellow-50/50',
      gradient: 'from-yellow-50 to-yellow-100/50',
      badge: Zap,
      description: 'Making progress on these concepts',
      progress: '60-74%'
    },
    'Needs Practice': {
      icon: BookOpen,
      color: 'text-red-600',
      bg: 'bg-red-50',
      border: 'border-red-200',
      iconBg: 'bg-red-100',
      lightBg: 'bg-red-50/50',
      gradient: 'from-red-50 to-red-100/50',
      badge: Lightbulb,
      description: 'These concepts need more attention',
      progress: '0-59%'
    }
  };

  const orderedLevels = ['Mastered', 'Proficient', 'Developing', 'Needs Practice'];
  
  // Group concepts and add index
  const groupedConcepts = conceptData.reduce((acc, concept, overallIndex) => {
    const level = concept.stats.mastery_level;
    if (!acc[level]) {
      acc[level] = [];
    }
    acc[level].push({ ...concept, overallIndex: overallIndex + 1 });
    return acc;
  }, {});

  // MasteryLevelHeader remains the same...
  const MasteryLevelHeader = ({ level, count }) => {
    const config = masteryLevelConfig[level];
    const Icon = config.icon;

    return (
      <div className={`group transition-all duration-200 ${expandedGroup === level ? config.bg : 'hover:bg-gray-50'}`}>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between p-4 gap-4">
          <div className="flex items-center gap-4">
            <div className={`p-3 rounded-xl ${config.iconBg} transform transition-transform 
              group-hover:scale-110 ${expandedGroup === level ? 'scale-110' : ''}`}>
              <Icon className={`w-6 h-6 ${config.color}`} />
            </div>
            <div>
              <div className="flex items-center gap-2 flex-wrap">
                <h3 className={`font-semibold ${config.color}`}>{level}</h3>
                <span className={`text-sm ${config.color} px-2 py-0.5 rounded-full ${config.lightBg}`}>
                  {config.progress}
                </span>
              </div>
              <p className="text-sm text-gray-600 mt-1">{config.description}</p>
            </div>
          </div>
          <div className="flex items-center gap-3 ml-14 sm:ml-0">
            <div className={`px-3 py-1 rounded-full ${config.lightBg} ${config.color}`}>
              <span className="text-sm font-medium">{count} concepts</span>
            </div>
            {expandedGroup === level ? (
              <ChevronUp className={`w-5 h-5 ${config.color}`} />
            ) : (
              <ChevronDown className="w-5 h-5 text-gray-400" />
            )}
          </div>
        </div>
      </div>
    );
  };

  // Updated ConceptCard
  const ConceptCard = ({ concept }) => {
    const config = masteryLevelConfig[concept.stats.mastery_level];
    const Badge = config.badge;

    return (
      <motion.div
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className={`group rounded-lg border ${config.border} transition-all duration-200
          hover:shadow-md bg-gradient-to-br ${config.gradient}`}
      >
        <div className="p-4">
          <div className="flex items-center gap-3">
            <div className={`p-2 rounded-lg ${config.iconBg} group-hover:scale-110 transition-transform`}>
              <Badge className={`w-4 h-4 ${config.color}`} />
            </div>
            <div className="flex-1">
              <h4 className="font-medium text-gray-900 group-hover:text-gray-700 transition-colors">
                Concept
              </h4>
            </div>
          </div>

          {/* Concept Info Button */}
          <div className="mt-3">
            <ConceptInfo concept={{ ...concept, mastery_level: concept.stats.mastery_level }} />
          </div>
        </div>
      </motion.div>
    );
  };

  // EmptyState remains the same...
  const EmptyState = () => (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm p-8 text-center"
    >
      <div className="w-16 h-16 mx-auto mb-4 bg-gray-100 rounded-full 
                    flex items-center justify-center">
        <BookOpen className="w-8 h-8 text-gray-400" />
      </div>
      <h3 className="text-xl font-semibold text-gray-900 mb-2">
        No Concepts Available
      </h3>
      <p className="text-gray-600 max-w-sm mx-auto">
        There are currently no concepts to analyze. Concepts will appear here as you progress through your learning journey.
      </p>
    </motion.div>
  );

  if (!conceptData.length) {
    return <EmptyState />;
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Mastery Level Groups */}
      <div className="space-y-4">
        {orderedLevels.map(level => {
          const concepts = groupedConcepts[level] || [];
          if (concepts.length === 0) return null;

          return (
            <motion.div
              key={level}
              layout
              className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100"
            >
              <button
                onClick={() => setExpandedGroup(expandedGroup === level ? null : level)}
                className="w-full text-left"
              >
                <MasteryLevelHeader level={level} count={concepts.length} />
              </button>

              <AnimatePresence>
                {expandedGroup === level && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className="overflow-hidden"
                  >
                    <div className="p-4 grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                      {concepts.map(concept => (
                        <ConceptCard key={concept.concept_id} concept={concept} />
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default ConceptAnalysis;