import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { QuestionProvider, useQuestion } from './QuestionContext';
import useQuestionTimer from '../../hooks/useQuestionTimer';
import useQuestionSubmission from '../../hooks/useQuestionSubmission';
import useTestProgress from '../../hooks/useTestProgress';
import QuestionHeader from './QuestionHeader';
import QuestionContent from './QuestionContent';
import OptionsGrid from './OptionsGrid';
import ActionButtons from './ActionButtons';
import ProgressDots from './ProgressDots';
import LoadingState from './LoadingState';
import ErrorState from './ErrorState';
import ConfirmationModal from './ConfirmationModal';

// Animation variants
const pageTransition = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.3 } 
  },
  exit: { 
    opacity: 0, 
    y: -20,
    transition: { duration: 0.2 } 
  }
};

// Status Legend Component
const StatusLegend = () => (
  <div className="space-y-3 mt-6 pt-6 border-t border-gray-200">
    <h3 className="text-sm font-semibold text-gray-700 mb-2">
      Status Legend
    </h3>
    <div className="flex items-center gap-2 text-sm text-gray-600">
      <span className="w-3 h-3 rounded-full bg-purple-600" />
      <span>Current Question</span>
    </div>
    <div className="flex items-center gap-2 text-sm text-gray-600">
      <span className="w-3 h-3 rounded-full bg-green-500" />
      <span>Answered</span>
    </div>
    <div className="flex items-center gap-2 text-sm text-gray-600">
      <span className="w-3 h-3 rounded-full bg-orange-500" />
      <span>"I Don't Know"</span>
    </div>
    <div className="flex items-center gap-2 text-sm text-gray-600">
      <span className="w-3 h-3 rounded-full bg-gray-200" />
      <span>Not Attempted</span>
    </div>
  </div>
);

// TimeoutOverlay Component
const TimeoutOverlay = () => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center"
  >
    <div className="bg-white rounded-xl p-8 max-w-md w-full mx-4 shadow-2xl">
      <div className="text-center">
        <svg 
          className="w-16 h-16 text-red-500 mx-auto mb-4" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <h2 className="text-2xl font-bold text-gray-900 mb-2">Time's Up!</h2>
        <p className="text-gray-600 mb-4">
          Your test has been automatically submitted.
        </p>
        <div className="animate-pulse">
          <p className="text-sm text-gray-500">
            Redirecting to results...
          </p>
        </div>
      </div>
    </div>
  </motion.div>
);

const QuestionDisplayContent = ({ onComplete }) => {
  const navigate = useNavigate();
  const { state, actions } = useQuestion();
  const { handleSubmit, isSubmitting } = useQuestionSubmission(onComplete);
  const { currentQuestionTime, totalTimeRemaining, isTimeUp } = useQuestionTimer();
  const { progress, totalQuestions } = useTestProgress();
  
  const [showIDKModal, setShowIDKModal] = useState(false);
  const [showEndTestModal, setShowEndTestModal] = useState(false);
  const [showTimeoutOverlay, setShowTimeoutOverlay] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);
  const [initializationError, setInitializationError] = useState(null);
  const hasInitialized = useRef(false);
  const isInitializing = useRef(true);

  useEffect(() => {
    const initializeTest = async () => {
      if (hasInitialized.current) return;

      try {
        const info = {
          testId: sessionStorage.getItem('testId'),
          name: sessionStorage.getItem('studentName'),
          grade: sessionStorage.getItem('studentGrade'),
          whatsapp: sessionStorage.getItem('studentWhatsapp'),
          email: sessionStorage.getItem('studentEmail')
        };

        // Validate required fields
        const requiredFields = ['testId', 'name', 'grade'];
        const missingFields = requiredFields.filter(field => !info[field]);

        if (missingFields.length > 0) {
          throw new Error(`Missing required information: ${missingFields.join(', ')}`);
        }

        setStudentInfo(info);
        
        // Set grade without fetching questions
        actions.setStudentGrade(info.grade, false);
        
        // Fetch questions
        await actions.setQuestions(info.grade);
        
        hasInitialized.current = true;

      } catch (error) {
        console.error('Error initializing test:', error);
        setInitializationError(error.message);
      } finally {
        isInitializing.current = false;
      }
    };

    initializeTest();

    return () => {
      // Cleanup if component unmounts during initialization
      isInitializing.current = false;
    };
  }, [actions]);

  // Handle time up
  useEffect(() => {
    if (isTimeUp && !state.isTestComplete && !showTimeoutOverlay) {
      const handleTimeout = async () => {
        setShowTimeoutOverlay(true);
        try {
          await handleSubmit('Unattempted');
          setTimeout(() => {
            navigate(`/results/${studentInfo?.testId}`);
          }, 3000);
        } catch (error) {
          console.error('Error handling timeout:', error);
          actions.setError('Failed to submit test after timeout');
        }
      };

      handleTimeout();
    }
  }, [isTimeUp, state.isTestComplete, navigate, actions, handleSubmit, studentInfo, showTimeoutOverlay]);

  const handleIDK = async () => {
    setShowIDKModal(false);
    await handleSubmit('IDK');
  };

  const handleEndTest = async () => {
    setShowEndTestModal(false);
    await handleSubmit('Unattempted');
    const testId = sessionStorage.getItem('testId');
    onComplete?.(testId);
  };

  // Handle initialization errors
  if (initializationError) {
    return (
      <ErrorState
        error={initializationError}
        onHome={() => navigate('/')}
      />
    );
  }

  // Show loading state during initialization or when loading questions
  if (isInitializing.current || state.isLoading) {
    return <LoadingState />;
  }

  // Show error state if there's an error
  if (state.error) {
    return (
      <ErrorState
        error={state.error}
        onRetry={() => {
          hasInitialized.current = false;
          isInitializing.current = true;
          window.location.reload();
        }}
        onHome={() => navigate('/')}
      />
    );
  }

  // Show error if no current question
  if (!state.currentQuestion) {
    return (
      <ErrorState
        error="No questions available for this grade level"
        onHome={() => navigate('/')}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Show timeout overlay if time is up */}
      {showTimeoutOverlay && <TimeoutOverlay />}

      {/* Header */}
      <QuestionHeader 
        questionNumber={state.currentQuestionIndex + 1}
        totalQuestions={totalQuestions}
        currentDifficulty={state.currentDifficulty}
        timeRemaining={totalTimeRemaining}
        progress={progress}
        studentName={studentInfo?.name}
        studentGrade={studentInfo?.grade}
      />
      
      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex gap-6">
          {/* Sidebar with question navigation */}
          <div className="hidden lg:block w-72 flex-shrink-0">
            <div className="sticky top-6 bg-white rounded-xl shadow-sm border border-gray-200 p-6">
              <ProgressDots
                currentIndex={state.currentQuestionIndex}
                totalQuestions={totalQuestions}
                answers={state.answers}
              />
              <StatusLegend />
            </div>
          </div>

          {/* Question Area */}
          <div className="flex-1 max-w-3xl">
            <div className="space-y-6">
              <motion.div
                key={state.currentQuestion._id}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={pageTransition}
              >
                {/* Question Content */}
                <QuestionContent 
                  content={state.currentQuestion?.question_text}
                  questionNumber={state.currentQuestionIndex + 1}
                  totalQuestions={totalQuestions}
                  difficulty={state.currentDifficulty}
                />

                {/* Options */}
                <div className="bg-white rounded-xl shadow-sm">
                  <OptionsGrid
                    options={state.currentQuestion?.options}
                    selectedAnswer={state.selectedAnswer}
                    onSelectAnswer={(answer) => 
                      actions.setSelectedAnswer(answer)
                    }
                    isDisabled={isSubmitting || showTimeoutOverlay}
                    difficulty={state.currentDifficulty}
                    grade={studentInfo?.grade}
                  />
                </div>

                {/* Action Buttons */}
                <ActionButtons
                  onIDK={() => setShowIDKModal(true)}
                  onSubmit={() => handleSubmit()}
                  onEndTest={() => setShowEndTestModal(true)}
                  selectedAnswer={state.selectedAnswer}
                  isSubmitting={isSubmitting}
                  timeRemaining={totalTimeRemaining}
                  questionNumber={state.currentQuestionIndex + 1}
                  totalQuestions={totalQuestions}
                  disabled={showTimeoutOverlay}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </main>

      {/* Modals */}
      {showIDKModal && !showTimeoutOverlay && (
        <ConfirmationModal
          message="Would you like to skip this question by marking it as 'I Don’t Know'?"
          onConfirm={handleIDK}
          onCancel={() => setShowIDKModal(false)}
        />
      )}
      
      {showEndTestModal && !showTimeoutOverlay && (
        <ConfirmationModal
          message="Are you sure you want to end the test? Remaining questions will be marked as unattempted."
          onConfirm={handleEndTest}
          onCancel={() => setShowEndTestModal(false)}
        />
      )}
    </div>
  );
};

const QuestionDisplay = ({ onComplete }) => {
  return (
    <QuestionProvider>
      <QuestionDisplayContent onComplete={onComplete} />
    </QuestionProvider>
  );
};

export default QuestionDisplay;