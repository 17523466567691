// src/components/results/common/ExpandableSection/SectionHeader.jsx
import React from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const SectionHeader = ({ 
  title, 
  subtitle, 
  icon: Icon, 
  isOpen, 
  onClick, 
  badge 
}) => (
  <button
    onClick={onClick}
    className="w-full px-6 py-4 flex items-center justify-between 
      hover:bg-gray-50 transition-colors"
  >
    <div className="flex items-center gap-3">
      <div className="w-10 h-10 rounded-full bg-purple-50 flex items-center justify-center">
        <Icon className="w-5 h-5 text-purple-600" />
      </div>
      <div className="text-left">
        <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
        {subtitle && (
          <p className="text-sm text-gray-500">{subtitle}</p>
        )}
      </div>
    </div>
    <div className="flex items-center gap-3">
      {badge && (
        <span className="px-2.5 py-0.5 rounded-full text-sm font-medium 
          bg-purple-100 text-purple-700">
          {badge}
        </span>
      )}
      {isOpen ? (
        <ChevronUpIcon className="w-5 h-5 text-gray-400" />
      ) : (
        <ChevronDownIcon className="w-5 h-5 text-gray-400" />
      )}
    </div>
  </button>
);

export default SectionHeader;