import React, { useState } from 'react';
import { motion } from 'framer-motion';
import StatCard from './StatCard';
import {
  CheckCircleIcon,
  ChartBarIcon,
  ClockIcon
} from '@heroicons/react/24/outline';

const generateStats = (summary) => [
  {
    label: 'Correct Answers',
    value: `${summary.correctAnswers}/${summary.totalQuestions}`,
    icon: CheckCircleIcon,
    color: 'text-green-600',
    bgColor: 'bg-green-100'
  },
  {
    label: 'Accuracy Rate',
    value: `${Math.floor(summary.accuracy)}%`,
    icon: ChartBarIcon,
    color: 'text-blue-600',
    bgColor: 'bg-blue-100'
  },
  {
    label: 'Average Time',
    value: `${summary.averageTime.toFixed(1)}s`,
    icon: ClockIcon,
    color: 'text-purple-600',
    bgColor: 'bg-purple-100'
  },
  {
    label: 'Total Time',
    value: `${(summary.totalTime / 60).toFixed(1)} min`,
    icon: ClockIcon,
    color: 'text-yellow-600',
    bgColor: 'bg-yellow-100'
  }
];

const QuickStats = ({ summary }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  if (!summary) return null;
  
  const stats = generateStats(summary);

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4"
    >
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onHoverStart={() => setHoveredIndex(index)}
          onHoverEnd={() => setHoveredIndex(null)}
        >
          <StatCard 
            stat={stat} 
            index={index} 
            isHovered={hoveredIndex === index}
          />
        </motion.div>
      ))}
    </motion.div>
  );
};

export default QuickStats;