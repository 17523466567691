// src/components/results/performance/QuickStats/StatCard.jsx
import React from 'react';
import { motion } from 'framer-motion';

const StatCard = ({ stat, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: index * 0.1 }}
    className="bg-white rounded-xl shadow-sm p-6"
  >
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-500">{stat.label}</p>
        <p className={`text-2xl font-bold ${stat.color}`}>{stat.value}</p>
      </div>
      <stat.icon className={`w-8 h-8 ${stat.color}`} />
    </div>
  </motion.div>
);

export default StatCard;