import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import NameInput from './NameInput';
import GradeSelect from './GradeSelect';
import WhatsAppInput from './WhatsAppInput';
import EmailInput from './EmailInput';

const FormStep = ({ children, isVisible }) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

const ProgressIndicator = ({ currentStep, totalSteps }) => (
  <div className="flex gap-2 justify-center mb-3 sm:mb-4">
    {[...Array(totalSteps)].map((_, index) => (
      <motion.div
        key={index}
        className={`h-1 sm:h-1.5 rounded-full ${
          index <= currentStep 
            ? 'bg-gradient-to-r from-[#7F56D9] to-[#EA4B0D]' 
            : 'bg-gray-200'
        }`}
        style={{ width: `${100 / totalSteps}%` }}
        initial={{ scale: 0.8 }}
        animate={{ scale: index === currentStep ? 1.1 : 1 }}
        transition={{ duration: 0.3 }}
      />
    ))}
  </div>
);

const StudentForm = ({ onSuccess, initialData, googleAuthenticated = false }) => {
  const [formData, setFormData] = useState({
    name: '',
    grade: '',
    whatsapp: '',
    email: '',
    picture: ''
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isReturningUser, setIsReturningUser] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    grade: '',
    whatsapp: '',
    email: '',
    general: ''
  });

  const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000/api';

  useEffect(() => {
    if (initialData) {
      setFormData(prev => ({
        ...prev,
        name: initialData.name || prev.name,
        email: initialData.email || prev.email,
        picture: initialData.picture || prev.picture
      }));

      const checkExistingUser = async () => {
        try {
          const response = await fetch(
            `${API_URL}/user-questions?email=${encodeURIComponent(initialData.email)}`
          );
          
          if (!response.ok) {
            throw new Error('Failed to check user status');
          }

          const data = await response.json();
          
          if (data.success && data.userExists) {
            setIsReturningUser(true);
            if (data.whatsapp) {
              setFormData(prev => ({
                ...prev,
                whatsapp: data.whatsapp
              }));
            }
          }
        } catch (error) {
          console.error('Error checking user:', error);
          setIsReturningUser(false);
        }
      };

      checkExistingUser();
    }
  }, [initialData, API_URL]);

  const validateForm = () => {
    const newErrors = {
      grade: '',
      whatsapp: '',
      general: ''
    };

    // Always validate grade
    if (!formData.grade) {
      newErrors.grade = 'Please select a grade';
      setErrors(prev => ({ ...prev, ...newErrors }));
      return false;
    }

    // Only validate other fields if submitting the final form
    if (!isReturningUser && currentStep === getTotalSteps() - 1) {
      if (!formData.whatsapp?.trim()) {
        newErrors.whatsapp = 'WhatsApp number is required';
        setErrors(prev => ({ ...prev, ...newErrors }));
        return false;
      }

      if (!googleAuthenticated) {
        if (!formData.name?.trim()) {
          setErrors(prev => ({ ...prev, name: 'Name is required' }));
          return false;
        }
        if (!formData.email?.trim()) {
          setErrors(prev => ({ ...prev, email: 'Email is required' }));
          return false;
        }
      }
    }

    setErrors(prev => ({ ...prev, ...newErrors }));
    return true;
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };

  const getTotalSteps = () => {
    if (isReturningUser) {
      return 1; // Only grade for returning users
    }
    return googleAuthenticated ? 2 : 4; // Grade + WhatsApp for new Google users, full form otherwise
  };

  const handleNext = () => {
    if (validateForm() && currentStep < getTotalSteps() - 1) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0 && !isReturningUser) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setErrors(prev => ({ ...prev, general: '' }));

    try {
      const response = await fetch(`${API_URL}/start-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name.trim(),
          grade: formData.grade,
          whatsapp: formData.whatsapp,
          email: formData.email.trim().toLowerCase(),
          googleAuthenticated,
          picture: formData.picture,
          isReturningUser
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to start test');
      }

      const sessionData = {
        testId: data.test_id,
        studentName: formData.name.trim(),
        studentGrade: formData.grade,
        studentWhatsapp: formData.whatsapp,
        studentEmail: formData.email.trim().toLowerCase(),
        studentPicture: formData.picture,
        startTime: new Date().toISOString()
      };

      Object.entries(sessionData).forEach(([key, value]) => {
        if (value) {
          sessionStorage.setItem(key, value);
        }
      });

      onSuccess?.();

    } catch (error) {
      console.error('Error starting test:', error);
      setErrors(prev => ({
        ...prev,
        general: error.message || 'Failed to start test. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    
    if (!formData.grade) {
      setErrors(prev => ({
        ...prev,
        grade: 'Please select a grade'
      }));
      return;
    }

    // For returning users or final step, submit the form
    if (isReturningUser || currentStep === getTotalSteps() - 1) {
      handleSubmit(e);
    } else {
      // For new users, move to next step
      handleNext();
    }
  };

  const getButtonText = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center">
          <svg className="animate-spin h-4 w-4 mr-2" viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span className="text-sm">Starting Test...</span>
        </div>
      );
    }

    // For returning users, always show "Start Test"
    if (isReturningUser) {
      return 'Start Test';
    }

    // For new users, show "Continue" unless it's the final step
    const isLastStep = currentStep === getTotalSteps() - 1;
    return isLastStep ? 'Start Test' : 'Continue';
  };

  const renderFormContent = () => {
    if (isReturningUser) {
      return (
        <FormStep isVisible={true}>
          <GradeSelect
            value={formData.grade}
            onChange={(value) => handleInputChange('grade', value)}
            error={errors.grade}
            disabled={loading}
          />
        </FormStep>
      );
    }

    if (googleAuthenticated) {
      return (
        <>
          <FormStep isVisible={currentStep === 0}>
            <GradeSelect
              value={formData.grade}
              onChange={(value) => handleInputChange('grade', value)}
              error={errors.grade}
              disabled={loading}
            />
          </FormStep>
          <FormStep isVisible={currentStep === 1}>
            <WhatsAppInput
              value={formData.whatsapp}
              onChange={(value) => handleInputChange('whatsapp', value)}
              error={errors.whatsapp}
              disabled={loading}
            />
          </FormStep>
        </>
      );
    }

    return (
      <>
        <FormStep isVisible={currentStep === 0}>
          <NameInput
            value={formData.name}
            onChange={(value) => handleInputChange('name', value)}
            disabled={loading}
            error={errors.name}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 1}>
          <EmailInput
            value={formData.email}
            onChange={(value) => handleInputChange('email', value)}
            error={errors.email}
            disabled={loading}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 2}>
          <GradeSelect
            value={formData.grade}
            onChange={(value) => handleInputChange('grade', value)}
            error={errors.grade}
            disabled={loading}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 3}>
          <WhatsAppInput
            value={formData.whatsapp}
            onChange={(value) => handleInputChange('whatsapp', value)}
            error={errors.whatsapp}
            disabled={loading}
          />
        </FormStep>
      </>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="w-full bg-white rounded-xl shadow-lg p-3 sm:p-6 backdrop-blur-sm relative overflow-hidden"
    >
      {/* Gradient Border */}
      <div className="absolute top-0 right-0 w-full h-1 sm:h-1.5 bg-gradient-to-r from-[#7F56D9] via-[#EA4B0D] to-[#112F3B]" />
      
      {/* Form Header */}
      <div className="text-center mb-3 sm:mb-4">
        {googleAuthenticated && formData.picture ? (
          <motion.div 
            className="flex flex-col items-center gap-2"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
          >
            <div className="relative">
              <motion.div
                className="absolute -inset-1 bg-gradient-to-r from-[#7F56D9] to-[#EA4B0D] rounded-full blur-sm"
                animate={{ rotate: 360 }}
                transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
              />
              <img 
                src={formData.picture} 
                alt={formData.name} 
                className="relative w-20 h-20 sm:w-20 sm:h-20 rounded-full shadow-lg border-2 border-white"
              />
            </div>
            <div>
              <h3 className="text-sm sm:text-base font-semibold text-[#112F3B]">
                {isReturningUser ? `Welcome back, ${formData.name}!` : formData.name}
              </h3>
              <p className="text-xs text-gray-600">{formData.email}</p>
            </div>
          </motion.div>
        ) : (
          <>
            <motion.h2 
              className="text-lg sm:text-xl font-bold text-[#112F3B]"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              Welcome to BMI
            </motion.h2>
            <motion.h3 
              className="text-base sm:text-lg font-semibold text-[#112F3B]"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              Bureau of Math Investigation
            </motion.h3>
          </>
        )}
      </div>

      {!isReturningUser && (
        <ProgressIndicator 
          currentStep={currentStep} 
          totalSteps={getTotalSteps()} 
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4">
        <AnimatePresence>
          {errors.general && (
            <motion.div 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="p-2 sm:p-3 bg-red-50 border border-red-200 rounded-lg"
            >
              <p className="text-xs text-red-600">{errors.general}</p>
            </motion.div>
          )}
        </AnimatePresence>

        {renderFormContent()}

        <div className="flex justify-between gap-2 sm:gap-3 mt-4">
          {currentStep > 0 && !isReturningUser && (
            <motion.button
              type="button"
              onClick={handleBack}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-3 sm:px-4 py-2.5 text-[#7F56D9] border border-[#7F56D9] rounded-lg
                hover:bg-[#7F56D9]/5 transition-colors duration-300 text-sm"
            >
              Back
            </motion.button>
          )}
          
          <motion.button
            type={isReturningUser || currentStep === getTotalSteps() - 1 ? 'submit' : 'button'}
            onClick={handleButtonClick}
            disabled={loading}
            whileHover={{ scale: loading ? 1 : 1.02 }}
            whileTap={{ scale: loading ? 1 : 0.98 }}
            className={`flex-1 py-2.5 px-3 sm:px-4 rounded-lg text-white font-medium text-sm
              transition-all duration-300 relative overflow-hidden
              ${loading 
                ? 'bg-[#7F56D9]/60 cursor-not-allowed' 
                : 'bg-[#7F56D9] hover:bg-[#7F56D9]/90'
              }
              shadow-sm sm:shadow-md shadow-[#7F56D9]/20`}
          >
            {getButtonText()}
          </motion.button>
        </div>

        <AnimatePresence>
          {(isReturningUser || currentStep === getTotalSteps() - 1) && (
            <motion.div 
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="mt-3 sm:mt-4 space-y-2 sm:space-y-3"
            >
              <div className="flex items-center justify-center gap-2 text-center">
                <svg className="w-4 h-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className="text-xs text-gray-500">
                  Make sure you have a stable internet connection
                </p>
              </div>
              
              <motion.p 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="text-xs text-center text-gray-400"
              >
                By starting the test, you agree to our Terms of Service and Privacy Policy
              </motion.p>
            </motion.div>
          )}
        </AnimatePresence>
      </form>

      {/* Google Sign-in Option - Only show for new users who haven't authenticated */}
      <AnimatePresence>
        {currentStep === 0 && !googleAuthenticated && !isReturningUser && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="mt-4 sm:mt-6 text-center"
          >
            <p className="text-xs text-gray-500">
              Already have a Google account?{" "}
              <button
                type="button"
                onClick={() => {
                  // Handle Google sign-in trigger
                }}
                className="text-[#7F56D9] hover:underline font-medium"
              >
                Sign in with Google
              </button>
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default StudentForm;