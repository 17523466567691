import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuestion } from '../components/test/QuestionContext';

export const useQuestionSubmission = (onComplete) => {
  const navigate = useNavigate();
  const { state, actions } = useQuestion();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);
  const [apiBaseUrl, setApiBaseUrl] = useState('');

  useEffect(() => {
    setApiBaseUrl(import.meta.env.VITE_API_URL || 'http://localhost:3000');

    try {
      const info = {
        testId: sessionStorage.getItem('testId'),
        name: sessionStorage.getItem('studentName'),
        grade: sessionStorage.getItem('studentGrade'),
        whatsapp: sessionStorage.getItem('studentWhatsapp'),
        email: sessionStorage.getItem('studentEmail')
      };

      const missingFields = Object.entries(info)
        .filter(([_, value]) => !value)
        .map(([key]) => key);

      if (missingFields.length > 0) {
        throw new Error(`Missing required student information: ${missingFields.join(', ')}`);
      }

      setStudentInfo(info);
    } catch (error) {
      console.error('Error loading student info:', error);
      actions.setError('Student information is incomplete. Please start the test again.');
    }
  }, [actions]);

  const handleSubmit = async (answer = state.selectedAnswer) => {
    try {
      if (!studentInfo) {
        throw new Error('Student information is not available');
      }

      setIsSubmitting(true);
      
      const timeTaken = ((Date.now() - state.questionStartTime) / 1000).toFixed(1);
      const isCorrect = answer === state.currentQuestion.correct_answer;
      const previousDifficulty = state.currentDifficulty;

      // Binary performance calculation: 1 for correct, 0 for incorrect
      const performance = isCorrect ? 1 : 0;

      // Expected next difficulty based on current answer
      const expectedNextDifficulty = isCorrect ? 
        Math.min(previousDifficulty + 1, 5) : 
        Math.max(previousDifficulty - 1, 1);

      console.log('Processing answer:', {
        questionId: state.currentQuestion._id,
        answer,
        isCorrect,
        currentDifficulty: previousDifficulty,
        expectedNextDifficulty,
        performance
      });

      const answerData = {
        test_id: studentInfo.testId,
        student_id: studentInfo.name,
        student_info: {
          name: studentInfo.name,
          grade: studentInfo.grade,
          whatsapp: studentInfo.whatsapp,
          email: studentInfo.email
        },
        question_id: state.currentQuestion._id,
        question_number: state.currentQuestionIndex + 1,
        student_answer: answer,
        correct: isCorrect,
        time_taken: parseFloat(timeTaken),
        time_stamp: new Date().toISOString(),
        difficulty_level: previousDifficulty
      };

      // Submit answer to API
      const response = await fetch(`${apiBaseUrl}/responses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(answerData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server response:', errorData);
        throw new Error(errorData.message || 'Failed to submit answer');
      }

      // Add answer to local state
      actions.addAnswer({
        ...answerData,
        question_id: state.currentQuestion
      });

      // Check if this was the last question
      if (state.currentQuestionIndex >= state.testConfig.totalQuestions - 1) {
        console.log('Last question completed, ending test...');
        await handleTestCompletion(studentInfo.testId);
        return;
      }

      // Get next question with updated difficulty
      await actions.setNextQuestion(answerData, performance);

      // Verify difficulty change
      console.log('Difficulty transition:', {
        previous: previousDifficulty,
        current: state.currentDifficulty,
        expected: expectedNextDifficulty,
        change: state.currentDifficulty - previousDifficulty,
        wasCorrect: isCorrect
      });

    } catch (error) {
      console.error('Error submitting answer:', error);
      actions.setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTestCompletion = async (testId) => {
    try {
      if (!studentInfo) {
        throw new Error('Student information is missing');
      }

      actions.setTestComplete(true);

      const completionData = {
        test_id: testId,
        student_id: studentInfo.name,
        student_info: {
          name: studentInfo.name,
          grade: studentInfo.grade,
          whatsapp: studentInfo.whatsapp,
          email: studentInfo.email
        },
        answers: state.answers,
        unattemptedQuestions: [],
        final_difficulty: state.currentDifficulty
      };

      console.log('Completing test:', { 
        testId,
        finalDifficulty: state.currentDifficulty,
        totalQuestions: state.answers.length,
        difficultyProgression: state.answers.map(a => a.difficulty_level)
      });

      const response = await fetch(`${apiBaseUrl}/end-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(completionData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to end test');
      }

      navigate(`/results/${testId}`);

    } catch (error) {
      console.error('Error completing test:', error);
      actions.setError('Failed to complete test: ' + error.message);
    }
  };

  return {
    handleSubmit,
    isSubmitting,
    studentInfo,
    isStudentInfoValid: Boolean(studentInfo),
    hasError: !studentInfo,
    isLastQuestion: state.currentQuestionIndex >= state.testConfig.totalQuestions - 1,
    getCurrentQuestionNumber: () => state.currentQuestionIndex + 1,
    getTotalQuestions: () => state.testConfig.totalQuestions
  };
};

export default useQuestionSubmission;