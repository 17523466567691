// client/src/components/results/loading/ErrorState.js
import React from 'react';

const ErrorState = ({ error, onRetry, onHome }) => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-xl shadow-lg p-8 max-w-md w-full">
      <div className="text-center">
        {/* Error Icon */}
        <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
          <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        
        {/* Error Title */}
        <h2 className="text-2xl font-bold text-[#112F3B] mb-2">
          Error Loading Results
        </h2>
        
        {/* Error Message */}
        <p className="text-gray-600 mb-6">{error}</p>
        
        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row gap-3 justify-center">
          {/* Retry Button - Only shown if onRetry handler is provided */}
          {onRetry && (
            <button
              onClick={onRetry}
              className="px-6 py-2 bg-[#7F56D9] text-white rounded-lg hover:bg-[#7F56D9]/90 
                transition-all duration-300 shadow-lg shadow-[#7F56D9]/30"
            >
              Try Again
            </button>
          )}
          
          {/* Home Button */}
          <button
            onClick={onHome}
            className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 
              transition-all duration-300"
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorState;