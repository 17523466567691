import { useCallback } from 'react';
import { useQuestion } from '../components/test/QuestionContext';
import TEST_CONFIG from '../config/test.config';

export const useTestProgress = () => {
  const { state } = useQuestion();
  
  // Calculate progress metrics
  const calculateProgress = useCallback(() => {
    const questionsPerLevel = state.testConfig?.questionsPerLevel || TEST_CONFIG.TOTAL_QUESTIONS;
    const totalQuestions = state.testConfig?.totalQuestions || (TEST_CONFIG.TOTAL_QUESTIONS * 5);
    const currentIndex = state.currentQuestionIndex || 0;
    
    // Calculate current level and progress within level
    const currentLevel = Math.floor(currentIndex / questionsPerLevel) + 1;
    const questionsInCurrentLevel = (currentIndex % questionsPerLevel) + 1;
    
    // Calculate base progress percentage
    const progress = (currentIndex / totalQuestions) * 100;

    // Calculate distribution metrics
    const distribution = {
      current: state.questionsByDifficulty?.level_1?.length || 0,
      lower: state.questionsByDifficulty?.level_2?.length || 0,
      higher: state.questionsByDifficulty?.level_3?.length || 0,
    };

    // Calculate performance metrics
    const performanceMetrics = {
      questionsAttempted: state.answers?.length || 0,
      correctAnswers: state.answers?.filter(a => a.correct)?.length || 0,
      averageTime: calculateAverageTime(state.answers || []),
      currentDifficulty: state.currentDifficulty,
      difficultyProgress: calculateDifficultyProgress(state.answers || [])
    };

    return {
      // Basic progress
      progress,
      currentIndex,
      totalQuestions,
      
      // Level progress
      currentLevel,
      questionsInCurrentLevel,
      questionsPerLevel,
      
      // Navigation states
      isFirstQuestion: currentIndex === 0,
      isLastQuestion: currentIndex === totalQuestions - 1,
      isLastInLevel: questionsInCurrentLevel === questionsPerLevel,
      remainingQuestions: totalQuestions - currentIndex - 1,
      
      // Question distribution
      distribution,
      
      // Performance metrics
      performance: performanceMetrics,
      
      // Test status
      isComplete: state.isTestComplete,
      canContinue: !state.error && !state.isLoading && !state.isTestComplete,
      isLoading: state.isLoading,
      hasError: !!state.error
    };
  }, [
    state.testConfig,
    state.currentQuestionIndex,
    state.questionsByDifficulty,
    state.answers,
    state.currentDifficulty,
    state.isTestComplete,
    state.isLoading,
    state.error
  ]);

  // Helper function to calculate average time
  const calculateAverageTime = (answers) => {
    if (!answers.length) return 0;
    const totalTime = answers.reduce((sum, a) => sum + (a.time_taken || 0), 0);
    return totalTime / answers.length;
  };

  // Helper function to calculate difficulty progression
  const calculateDifficultyProgress = (answers) => {
    if (!answers.length) return [];

    return answers.map((answer, index) => ({
      questionNumber: index + 1,
      difficulty: answer.difficulty_level || 3,
      isCorrect: answer.correct,
      timeTaken: answer.time_taken || 0
    }));
  };

  // Get difficulty breakdown
  const getDifficultyBreakdown = useCallback(() => {
    const answers = state.answers || [];
    if (!answers.length) return {};

    return answers.reduce((acc, answer) => {
      const difficulty = answer.difficulty_level || 3;
      
      if (!acc[difficulty]) {
        acc[difficulty] = {
          count: 0,
          correct: 0,
          totalTime: 0
        };
      }
      
      acc[difficulty].count++;
      if (answer.correct) acc[difficulty].correct++;
      acc[difficulty].totalTime += answer.time_taken || 0;
      
      return acc;
    }, {});
  }, [state.answers]);

  return {
    ...calculateProgress(),
    difficultyBreakdown: getDifficultyBreakdown()
  };
};

export default useTestProgress;