import React, { createContext, useContext, useReducer, useState } from 'react';

const TestContext = createContext();

const initialState = {
  student: null,
  testId: null,
  currentQuestion: null,
  questionNumber: 0,
  answers: [],
  timeRemaining: 300,
  isTestComplete: false,
  testStarted: false
};

function testReducer(state, action) {
  switch (action.type) {
    case 'SET_STUDENT':
      return { ...state, student: action.payload };
    case 'SET_TEST_ID':
      return { ...state, testId: action.payload };
    case 'SET_CURRENT_QUESTION':
      return { ...state, currentQuestion: action.payload };
    case 'SET_QUESTION_NUMBER':
      return { ...state, questionNumber: action.payload };
    case 'ADD_ANSWER':
      return { ...state, answers: [...state.answers, action.payload] };
    case 'UPDATE_TIME':
      return { ...state, timeRemaining: action.payload };
    case 'COMPLETE_TEST':
      return { ...state, isTestComplete: true };
    case 'START_TEST':
      return {...state, testStarted: true};
    case 'END_TEST':
      return {...state, testStarted: false};
    default:
      return state;
  }
}

export function TestProvider({ children }) {
  const [state, dispatch] = useReducer(testReducer, initialState);

  return (
    <TestContext.Provider value={{ state, dispatch }}>
      {children}
    </TestContext.Provider>
  );
}

export function useTest() {
  const context = useContext(TestContext);
  if (!context) {
    throw new Error('useTest must be used within a TestProvider');
  }
  return context;
}