// src/services/questionService.js
class QuestionService {
  constructor() {
    this.apiBaseUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000';
  }

  async fetchQuestions(grade) {
    if (!grade) {
      throw new Error('Grade is required');
    }
  
    const email = sessionStorage.getItem('studentEmail');
    if (!email) {
      throw new Error('Student email is required');
    }
  
    try {
      const normalizedGrade = grade.toString().replace('G', '');
      
      const response = await fetch(
        `${this.apiBaseUrl}/questions?grade=${normalizedGrade}&email=${encodeURIComponent(email)}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to fetch questions');
      }
  
      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.message || 'Failed to fetch questions');
      }
  
      return {
        questions: {
          level_1: data.questions.level_1 || [],
          level_2: data.questions.level_2 || [],
          level_3: data.questions.level_3 || [],
          level_4: data.questions.level_4 || [],
          level_5: data.questions.level_5 || []
        }
      };
  
    } catch (error) {
      console.error('Error fetching questions:', error);
      throw error;
    }
  }

  async fetchNextAdaptiveQuestion(params) {
    const { currentDifficulty, excludeIds, grade } = params;

    if (!grade) {
      throw new Error('Grade is required');
    }

    try {
      const response = await fetch(
        `${this.apiBaseUrl}/questions/adaptive/test`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            currentDifficulty,
            excludeIds: Array.from(excludeIds || []),
            grade: grade.toString().replace('G', '')
          })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch next question');
      }

      const data = await response.json();
      
      if (!data?.success || !data?.data?.question) {
        throw new Error('No question received from server');
      }

      return {
        question: data.data.question,
        difficulty: currentDifficulty
      };

    } catch (error) {
      console.error('Error fetching adaptive question:', error);
      throw error;
    }
  }
}

const questionService = new QuestionService();
export default questionService;