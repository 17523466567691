import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const EmailInput = ({ value, onChange, error: parentError, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [localError, setLocalError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const validateEmail = (email) => {
    if (!email) return '';
    
    // Pre-validation for common mistakes
    if (!email.includes('@')) {
      return 'Email must contain @';
    }
    if (!email.includes('.')) {
      return 'Email must contain a domain (e.g., .com)';
    }
    
    // Comprehensive email validation with specific error messages
    if (/\s/.test(email)) {
      return 'Email cannot contain spaces';
    }
    
    // Regular expression for strict email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      // Check specific parts of the email for more helpful error messages
      const [localPart, domainPart] = email.split('@');
      
      if (localPart && localPart.length > 64) {
        return 'Email username is too long';
      }
      
      if (domainPart && !domainPart.includes('.')) {
        return 'Invalid domain format';
      }
      
      if (domainPart && domainPart.endsWith('.')) {
        return 'Domain cannot end with a period';
      }
      
      return 'Please enter a valid email address';
    }
    
    return '';
  };

  useEffect(() => {
    if (isDirty) {
      const error = validateEmail(value);
      setLocalError(error);
      setIsValid(!error);
    }
  }, [value, isDirty]);

  const handleChange = (e) => {
    setIsDirty(true);
    onChange(e.target.value.trim()); // Remove any accidental spaces
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsDirty(true); // Also validate on blur
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const error = isDirty ? (parentError || localError) : '';

  return (
    <div className="space-y-1">
      <motion.div
        initial={false}
        animate={{
          scale: isFocused ? 1.01 : 1,
        }}
        className="relative"
      >
        <div className="relative">
          <input
            type="email"
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            placeholder="Enter your email address"
            className={`w-full px-4 py-3 rounded-lg border-2 bg-white 
              focus:outline-none focus:ring-2 focus:ring-[#7F56D9]/20 transition-all duration-200
              ${error 
                ? 'border-red-300 focus:border-red-300' 
                : 'border-gray-200 hover:border-[#7F56D9]/30 focus:border-[#7F56D9]'
              }
              ${disabled ? 'bg-gray-50 cursor-not-allowed' : ''}
              pr-10 // Make room for the icon
            `}
            autoComplete="email"
          />

          {/* Validation Icon - Only show after user interaction and when there's input */}
          {isDirty && value && (
            <motion.div 
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              className="absolute right-3 top-1/2 -translate-y-1/2"
            >
              {!isValid ? (
                <motion.svg
                  className="w-5 h-5 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  initial={{ rotate: 180 }}
                  animate={{ rotate: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </motion.svg>
              ) : (
                <motion.svg
                  className="w-5 h-5 text-green-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 20
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </motion.svg>
              )}
            </motion.div>
          )}
        </div>

        {/* Helper Text for Email Format */}
        {isFocused && !error && !isValid && (
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-xs text-gray-500 mt-1"
          >
            Enter a valid email address (e.g., name@example.com)
          </motion.p>
        )}

        {/* Error Message with Animation */}
        {error && (
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-sm text-red-600 mt-1"
          >
            {error}
          </motion.p>
        )}
      </motion.div>
    </div>
  );
};

export default EmailInput;