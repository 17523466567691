// src/components/test/QuestionDisplay/components/QuestionState/LoadingState.js
import React from 'react';

const LoadingState = () => {
  return (
    <div className="min-h-[600px] flex items-center justify-center">
      <div className="text-center">
        {/* Animated loading spinner */}
        <div className="animate-spin rounded-full h-16 w-16 border-4 border-purple-600 border-t-transparent mx-auto mb-4"></div>
        <div className="space-y-2">
          <p className="text-gray-600 font-medium">Loading your test...</p>
          <p className="text-sm text-gray-500">Please wait while we prepare your questions</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingState;