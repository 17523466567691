import React from 'react';
import { motion } from 'framer-motion';
import { Card } from '../../common/Card';
import {
  CheckCircle,
  AlertCircle,
  Clock,
  Trophy,
  Target,
  Brain,
  Rocket,
  BookOpen,
  ArrowUpCircle,
  Medal,
  XCircle,
  Lightbulb
} from 'lucide-react';

/**
 * PerformanceInsights component displays a summary of a user's performance in a test or quiz.
 * It shows strengths, areas for improvement, and recommendations based on the provided summary data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.summary - An object containing the performance summary data.
 * @param {number} props.summary.accuracy - The overall accuracy percentage.
 * @param {number} props.summary.averageTime - The average time taken per question in seconds.
 * @param {number} props.summary.unattemptedCount - The number of unattempted questions.
 * @param {number} props.summary.idkCount - The number of questions marked as "I Don't Know".
 * @returns {JSX.Element} A card containing performance insights divided into sections.
 */

const PerformanceInsights = ({ summary }) => {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };

  const InsightSection = ({ title, items, type }) => {
    const getConfig = () => {
      switch (type) {
        case 'strength':
          return {
            bg: 'bg-green-50',
            border: 'border-green-100',
            title: 'text-green-700',
            icon: CheckCircle,
            iconColor: 'text-green-500'
          };
        case 'improvement':
          return {
            bg: 'bg-red-50',
            border: 'border-red-100',
            title: 'text-red-700',
            icon: AlertCircle,
            iconColor: 'text-red-500'
          };
        case 'recommendation':
          return {
            bg: 'bg-purple-50',
            border: 'border-purple-100',
            title: 'text-purple-700',
            icon: Lightbulb,
            iconColor: 'text-purple-500'
          };
        default:
          return {};
      }
    };

    const config = getConfig();

    return (
      <motion.div 
        {...fadeInUp}
        className={`p-5 ${config.bg} rounded-lg border ${config.border}`}
      >
        <div className="flex items-center gap-2 mb-3">
          <div className={`p-2 rounded-lg ${config.bg}`}>
            <config.icon className={`w-5 h-5 ${config.iconColor}`} />
          </div>
          <h4 className={`font-semibold ${config.title}`}>{title}</h4>
        </div>
        <ul className="space-y-3">
          {items.map((item, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-start gap-3"
            >
              <div className={`p-1 rounded-full ${config.bg} mt-1`}>
                <item.icon className={`w-4 h-4 ${config.iconColor}`} />
              </div>
              <span className="text-gray-700">{item.text}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    );
  };

  // Prepare the insights data
  const strengths = [
    ...(summary.accuracy >= 70 ? [{ icon: Trophy, text: 'High overall accuracy of ' + summary.accuracy.toFixed(1) + '%' }] : []),
    ...(summary.averageTime < 30 ? [{ icon: Clock, text: 'Efficient time management with average ' + summary.averageTime.toFixed(1) + 's per question' }] : []),
    ...(summary.unattemptedCount === 0 ? [{ icon: Target, text: 'Completed all questions successfully' }] : []),
  ];

  const improvements = [
    ...(summary.idkCount > 2 ? [{ icon: Brain, text: `${summary.idkCount} questions marked as "I Don't Know" - needs review` }] : []),
    ...(summary.averageTime > 45 ? [{ icon: Clock, text: 'Time management needs improvement - average ' + summary.averageTime.toFixed(1) + 's per question' }] : []),
    ...(summary.accuracy < 60 ? [{ icon: XCircle, text: 'Overall accuracy needs improvement - currently at ' + summary.accuracy.toFixed(1) + '%' }] : []),
  ];

  const recommendations = [
    ...(summary.averageTime > 30 ? [{ icon: Clock, text: 'Practice solving questions under timed conditions to improve speed' }] : []),
    ...(summary.idkCount > 2 ? [{ icon: BookOpen, text: 'Review fundamental concepts for questions marked as "I Don\'t Know"' }] : []),
    { icon: ArrowUpCircle, text: 'Focus on maintaining accuracy while improving time management' },
    { icon: Medal, text: 'Set personal goals for each practice session to track improvement' },
  ];

  return (
    <Card className="p-6">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="space-y-6"
      >
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 rounded-lg bg-purple-100">
            <Rocket className="w-6 h-6 text-purple-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Performance Insights</h3>
        </div>

        <div className="space-y-6">
          {strengths.length > 0 && (
            <InsightSection
              title="Strengths"
              items={strengths}
              type="strength"
            />
          )}

          {improvements.length > 0 && (
            <InsightSection
              title="Areas for Improvement"
              items={improvements}
              type="improvement"
            />
          )}

          <InsightSection
            title="Recommendations"
            items={recommendations}
            type="recommendation"
          />
        </div>
      </motion.div>
    </Card>
  );
};

export default PerformanceInsights;