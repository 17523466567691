// client/src/routes/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const testId = sessionStorage.getItem('testId');
  const studentName = sessionStorage.getItem('studentName');

  if (!testId || !studentName) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;