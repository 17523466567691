import React, { useState } from 'react';
import { motion } from 'framer-motion';

const GradeSelect = ({ value, onChange, error, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);

  const gradeOptions = [
    // { value: 'G1', label: 'Grade 1', category: 'Primary' },
    // { value: 'G2', label: 'Grade 2', category: 'Primary' },
    // { value: 'G3', label: 'Grade 3', category: 'Primary' },
    // { value: 'G4', label: 'Grade 4', category: 'Primary' },
    { value: 'G5', label: 'Grade 5', category: 'Primary' },
    { value: 'G6', label: 'Grade 6', category: 'Primary' },
    { value: 'G7', label: 'Grade 7', category: 'Middle' },
    { value: 'G8', label: 'Grade 8', category: 'Middle' },
    { value: 'G9', label: 'Grade 9', category: 'High' },
    { value: 'G10', label: 'Grade 10', category: 'High' },
    { value: 'G11', label: 'Grade 11', category: 'High' },
    { value: 'G12', label: 'Grade 12', category: 'High' }
  ];

  // Group grades by category
  const groupedGrades = gradeOptions.reduce((acc, grade) => {
    if (!acc[grade.category]) {
      acc[grade.category] = [];
    }
    acc[grade.category].push(grade);
    return acc;
  }, {});

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="space-y-1">
      <motion.div
        initial={false}
        animate={{
          scale: isFocused ? 1.01 : 1,
        }}
        className="relative"
      >
        <select
          value={value}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={disabled}
          className={`w-full px-3 sm:px-4 py-2.5 sm:py-3 rounded-lg border-2 bg-white appearance-none 
            text-sm sm:text-base
            focus:outline-none focus:ring-2 focus:ring-[#7F56D9]/20 transition-all duration-200
            ${error 
              ? 'border-red-300 focus:border-red-300' 
              : 'border-gray-200 hover:border-[#7F56D9]/30 focus:border-[#7F56D9]'
            }`}
        >
          <option value="">Select Grade</option>
          {Object.entries(groupedGrades).map(([category, grades]) => (
            <optgroup key={category} label={`${category} School`}>
              {grades.map(grade => (
                <option key={grade.value} value={grade.value}>
                  {grade.label}
                </option>
              ))}
            </optgroup>
          ))}
        </select>

        {/* Custom dropdown arrow */}
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <motion.svg
            animate={{
              rotate: isFocused ? 180 : 0,
            }}
            transition={{ duration: 0.2 }}
            className={`h-4 w-4 sm:h-5 sm:w-5 ${error ? 'text-red-400' : 'text-[#7F56D9]'}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </motion.svg>
        </div>

        {/* Validation Icon - Only show when a value is selected */}
        {value && (
          <div className="absolute right-8 sm:right-10 top-1/2 -translate-y-1/2">
            {error ? (
              <svg className="w-4 h-4 sm:w-5 sm:h-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            ) : (
              <svg className="w-4 h-4 sm:w-5 sm:h-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            )}
          </div>
        )}
      </motion.div>

      {/* Error Message */}
      {error && (
        <motion.p
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-xs sm:text-sm text-red-600 mt-1"
        >
          {error}
        </motion.p>
      )}
    </div>
  );
};

export default GradeSelect;