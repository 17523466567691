// src/components/test/QuestionDisplay/components/QuestionState/ErrorState.js
import React from 'react';

const ErrorIcon = () => (
  <svg 
    className="w-12 h-12 text-red-500 mb-4" 
    fill="none" 
    stroke="currentColor" 
    viewBox="0 0 24 24"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2" 
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
    />
  </svg>
);

const ErrorState = ({ 
  error, 
  onRetry, 
  onHome 
}) => {
  return (
    <div className="min-h-[600px] flex items-center justify-center p-4">
      <div className="bg-red-50 rounded-lg p-6 max-w-md w-full text-center">
        <div className="flex justify-center">
          <ErrorIcon />
        </div>
        <h3 className="text-lg font-semibold text-red-800 mb-2">
          Error Loading Test
        </h3>
        <p className="text-red-600 mb-6">
          {error}
        </p>
        <div className="flex flex-col sm:flex-row gap-3 justify-center">
          {onRetry && (
            <button
              onClick={onRetry}
              className="px-6 py-2 bg-purple-600 text-white rounded-lg 
                hover:bg-purple-700 transition-all duration-300
                shadow-lg shadow-purple-200"
            >
              Try Again
            </button>
          )}
          {onHome && (
            <button
              onClick={onHome}
              className="px-6 py-2 bg-gray-100 text-gray-700 rounded-lg 
                hover:bg-gray-200 transition-all duration-300"
            >
              Return Home
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorState;