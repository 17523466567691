import React from 'react';

// Simple className merger utility
const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const variantStyles = {
  default: 'bg-white border border-gray-200',
  filled: 'bg-gray-50',
  outline: 'border border-gray-200 bg-transparent',
  elevated: 'bg-white shadow-lg'
};

const sizeStyles = {
  sm: 'p-4',
  md: 'p-6',
  lg: 'p-8'
};

const Card = React.forwardRef(({ 
  children,
  className = "",
  variant = "default",
  size = "md",
  hover = true,
  onClick,
  ...props 
}, ref) => {
  const hoverClasses = hover ? [
    'hover:shadow-lg hover:border-gray-300',
    variant === 'elevated' && 'hover:shadow-xl',
    variant === 'outline' && 'hover:bg-gray-50',
    onClick && 'cursor-pointer'
  ].filter(Boolean).join(' ') : '';

  return (
    <div
      ref={ref}
      className={classNames(
        // Base styles
        "rounded-lg transition-all duration-200",
        
        // Variant styles
        variantStyles[variant],
        
        // Size styles
        sizeStyles[size],
        
        // Hover effects
        hoverClasses,
        
        // Custom className
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
});

Card.displayName = 'Card';

export { Card };