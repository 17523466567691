// src/components/results/common/ExpandableSection/index.jsx
import React, { useState } from 'react';
import SectionHeader from './SectionHeader';
import SectionContent from './SectionContent';

const ExpandableSection = ({ 
  title, 
  subtitle, 
  icon, 
  children, 
  defaultOpen = false,
  badge = null 
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <SectionHeader 
        title={title}
        subtitle={subtitle}
        icon={icon}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        badge={badge}
      />
      <SectionContent isOpen={isOpen}>
        {children}
      </SectionContent>
    </div>
  );
};

export default ExpandableSection;