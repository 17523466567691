// src/components/results/layout/ResultsContent.jsx
import React from 'react';
import ExpandableSection from '../common/ExpandableSection';
import {
  ChartBarIcon,
  BookOpenIcon,
  QuestionMarkCircleIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { ZapIcon } from 'lucide-react';
import {
  PerformanceCharts,
  PerformanceInsights,
  IDKAnalysis,
  GuessingAnalysis,
  Misconceptions,
  ConceptAnalysis
} from '../index';

const ResultsContent = ({ data }) => {
  const sections = [
    {
      id: 'mistakes',
      title: "Incorrect Answers",
      subtitle: "Analysis of mistakes and misconceptions",
      icon: ExclamationTriangleIcon,
      badge: `${data.misconceptions.length} mistakes`,
      content: <Misconceptions misconceptionsData={data.misconceptions} />
    },
    {
      id: 'knowledge-gaps',
      title: "Knowledge Gaps",
      subtitle: "Questions marked as 'I Don't Know'",
      icon: QuestionMarkCircleIcon,
      badge: `${data.results.filter(r => r.student_answer === 'IDK').length} questions`,
      content: <IDKAnalysis results={data.results} />
    },
    {
      id: 'quick-responses',
      title: "Did you guess these answers?",
      subtitle: "Questions answered in under 3 seconds",
      icon: ZapIcon,
      badge: `${data.results.filter(r => r.time_taken <= 3).length} questions`,
      content: <GuessingAnalysis results={data.results} />
    },
    {
      id: 'concepts',
      title: "Concept Mastery",
      subtitle: "Understanding of key concepts",
      icon: BookOpenIcon,
      badge: `${data.conceptAnalysis.length} concepts`,
      content: <ConceptAnalysis conceptData={data.conceptAnalysis} />
    },
    {
      id: 'performance',
      title: "Performance Overview",
      subtitle: "Your overall test performance analysis",
      icon: ChartBarIcon,
      defaultOpen: true,
      badge: `${data.summary.accuracy.toFixed(1)}%`,
      content: (
        <>
          <PerformanceCharts results={data.results} />
          <div className="mt-6">
            <PerformanceInsights summary={data.summary} />
          </div>
        </>
      )
    }
  ];

  return (
    <div className="space-y-6">
      {sections.map(section => (
        <ExpandableSection
          key={section.id}
          title={section.title}
          subtitle={section.subtitle}
          icon={section.icon}
          defaultOpen={section.defaultOpen}
          badge={section.badge}
        >
          {section.content}
        </ExpandableSection>
      ))}
    </div>
  );
};

export default ResultsContent;