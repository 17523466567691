import React, { useEffect, useRef, useCallback } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const MathDisplay = ({ content }) => {
  const containerRef = useRef(null);

  const processMath = useCallback((expression, isDisplay) => {
    try {
      const span = document.createElement('span');
      span.className = isDisplay ? 'math-display' : 'math-inline';
      katex.render(expression, span, {
        throwOnError: false,
        displayMode: isDisplay,
        strict: false,
        output: 'html',
        macros: {
          "\\R": "\\mathbb{R}",
          "\\N": "\\mathbb{N}",
          "\\Z": "\\mathbb{Z}",
          "\\Q": "\\mathbb{Q}",
          "\\C": "\\mathbb{C}"
        }
      });
      return span.outerHTML;
    } catch (error) {
      console.warn('KaTeX rendering warning:', error);
      return `<span class="math-error">${expression}</span>`;
    }
  }, []);

  const renderMath = useCallback((text) => {
    const regexInline = /\\\((.*?)\\\)/g;
    const regexDisplay = /\\\[(.*?)\\\]/g;
    let result = text;

    // Process display math first (to avoid nested matches)
    result = result.replace(regexDisplay, (_, match) => processMath(match, true));
    // Then process inline math
    result = result.replace(regexInline, (_, match) => processMath(match, false));

    return result;
  }, [processMath]);

  useEffect(() => {
    if (!containerRef.current || !content) return;

    try {
      const renderedContent = renderMath(content);
      containerRef.current.innerHTML = renderedContent;

      const mathElements = containerRef.current.getElementsByClassName('katex');
      const handleMouseEnter = (e) => {
        e.currentTarget.style.transform = 'scale(1.01)';
      };
      const handleMouseLeave = (e) => {
        e.currentTarget.style.transform = 'scale(1)';
      };

      Array.from(mathElements).forEach(element => {
        element.addEventListener('click', (e) => e.stopPropagation());
        element.addEventListener('mouseenter', handleMouseEnter);
        element.addEventListener('mouseleave', handleMouseLeave);
        element.style.transition = 'transform 0.2s ease';
      });

      return () => {
        Array.from(mathElements).forEach(element => {
          element.removeEventListener('click', (e) => e.stopPropagation());
          element.removeEventListener('mouseenter', handleMouseEnter);
          element.removeEventListener('mouseleave', handleMouseLeave);
        });
      };
    } catch (error) {
      console.error('Math rendering error:', error);
      containerRef.current.innerHTML = `<div class="math-fallback">${content}</div>`;
    }
  }, [content, renderMath]);

  return (
    <div 
      ref={containerRef}
      className="math-content relative p-3 text-base text-gray-800 
                 overflow-x-auto bg-white rounded-lg shadow-sm
                 hover:shadow-md transition-shadow duration-200
                 border border-gray-100"
    >
      <style>{`
        .math-content {
          line-height: 1.5;
          font-family: system-ui, -apple-system, sans-serif;
          scrollbar-width: thin;
          scrollbar-color: #E5E7EB transparent;
        }
        
        .math-content .katex { font-size: 1em; }
        
        .math-content .math-display {
          display: block;
          margin: 0.75em 0;
          overflow-x: auto;
          text-align: center;
        }
        
        .math-content .math-inline { padding: 0 0.1em; }
        
        .math-content .math-error {
          color: #DC2626;
          background: #FEE2E2;
          padding: 0.2em 0.4em;
          border-radius: 0.25rem;
        }
        
        .math-content .math-fallback {
          color: #374151;
          background: #F3F4F6;
          padding: 0.75em;
          border-radius: 0.375rem;
          border: 1px dashed #D1D5DB;
        }
        
        .math-content::-webkit-scrollbar {
          height: 4px;
          width: 4px;
        }
        
        .math-content::-webkit-scrollbar-track { background: transparent; }
        
        .math-content::-webkit-scrollbar-thumb {
          background-color: #E5E7EB;
          border-radius: 2px;
        }
        
        .math-content::-webkit-scrollbar-thumb:hover { background-color: #D1D5DB; }
      `}</style>
    </div>
  );
};

export default MathDisplay;