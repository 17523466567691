import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const countryPhoneCodes = [
  { code: '+971', country: 'United Arab Emirates' },
  { code: '+1', country: 'United States' },
  { code: '+44', country: 'United Kingdom' },
  { code: '+91', country: 'India' },
  { code: '+966', country: 'Saudi Arabia' },
  { code: '+965', country: 'Kuwait' },
  { code: '+973', country: 'Bahrain' },
  { code: '+974', country: 'Qatar' },
  { code: '+968', country: 'Oman' },
  { code: '+961', country: 'Lebanon' },
  { code: '+20', country: 'Egypt' },
  { code: '+962', country: 'Jordan' },
  { code: '+963', country: 'Syria' },
  { code: '+964', country: 'Iraq' },
  { code: '+98', country: 'Iran' },
  { code: '+90', country: 'Turkey' },
  { code: '+60', country: 'Malaysia' },
  { code: '+65', country: 'Singapore' },
  { code: '+63', country: 'Philippines' },
  { code: '+66', country: 'Thailand' }
];

const WhatsAppInput = ({ value, onChange, error: parentError, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [countryCode, setCountryCode] = useState('+971');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [localError, setLocalError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const validatePhoneNumber = (number) => {
    if (!number) return '';
    if (number.length < 9) {
      return 'Phone number must be at least 9 digits';
    }
    if (number.length > 10) {
      return 'Phone number cannot exceed 10 digits';
    }
    return '';
  };

  useEffect(() => {
    if (isDirty) {
      const error = validatePhoneNumber(phoneNumber);
      setLocalError(error);
      setIsValid(phoneNumber.length >= 9 && phoneNumber.length <= 10);
    }
  }, [phoneNumber, isDirty]);

  const handleCountryCodeChange = (e) => {
    const newCountryCode = e.target.value;
    setCountryCode(newCountryCode);
    const fullNumber = `${newCountryCode}${phoneNumber}`;
    onChange(fullNumber);
  };

  const handlePhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value.replace(/[^\d]/g, '');
    if (newPhoneNumber.length <= 10) {
      setPhoneNumber(newPhoneNumber);
      setIsDirty(true);
      const fullNumber = `${countryCode}${newPhoneNumber}`;
      onChange(fullNumber);
    }
  };

  const error = isDirty ? (parentError || localError) : '';

  return (
    <div className="space-y-1">
      <motion.div
        initial={false}
        animate={{ scale: isFocused ? 1.01 : 1 }}
        className="flex flex-row gap-1.5 sm:gap-2"
      >
        {/* Country Code Select */}
        <div className="relative w-20 sm:w-24">
          <select
            value={countryCode}
            onChange={handleCountryCodeChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={disabled}
            className={`w-full px-1.5 sm:px-2 py-2.5 sm:py-3 rounded-lg border-2 bg-white appearance-none
              text-sm sm:text-base
              focus:outline-none focus:ring-2 focus:ring-[#7F56D9]/20 transition-all duration-200
              ${error 
                ? 'border-red-300 focus:border-red-300' 
                : 'border-gray-200 hover:border-[#7F56D9]/30 focus:border-[#7F56D9]'
              }`}
          >
            <option value={countryCode}>{countryCode}</option>
            {countryPhoneCodes
              .filter(item => item.code !== countryCode)
              .map(({ code, country }) => (
                <option key={code} value={code}>
                  {code} {country}
                </option>
              ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center pr-1.5 sm:pr-2 pointer-events-none">
            <motion.svg
              animate={{ rotate: isFocused ? 180 : 0 }}
              transition={{ duration: 0.2 }}
              className={`h-3 w-3 sm:h-4 sm:w-4 ${error ? 'text-red-400' : 'text-[#7F56D9]'}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </motion.svg>
          </div>
        </div>

        {/* Phone Number Input */}
        <div className="relative flex-1">
          <input
            type="tel"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={disabled}
            placeholder="501234567"
            className={`w-full px-3 sm:px-4 py-2.5 sm:py-3 rounded-lg border-2 bg-white
              text-sm sm:text-base placeholder:text-gray-400 placeholder:text-sm sm:placeholder:text-base
              focus:outline-none focus:ring-2 focus:ring-[#7F56D9]/20 transition-all duration-200
              ${error 
                ? 'border-red-300 focus:border-red-300' 
                : 'border-gray-200 hover:border-[#7F56D9]/30 focus:border-[#7F56D9]'
              }`}
            maxLength="10"
          />
          
          {isDirty && phoneNumber && (
            <div className="absolute right-2 sm:right-3 top-1/2 -translate-y-1/2">
              {!isValid ? (
                <svg className="w-4 h-4 sm:w-5 sm:h-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              ) : (
                <svg className="w-4 h-4 sm:w-5 sm:h-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              )}
            </div>
          )}
        </div>
      </motion.div>

      {/* Label */}
      <div className="flex items-center gap-1 mt-1">
        <svg className="w-3.5 h-3.5 text-[#7F56D9]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p className="text-xs text-gray-500">Enter WhatsApp number</p>
      </div>

      {/* Error Message */}
      {error && (
        <motion.p
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-xs sm:text-sm text-red-600 mt-1"
        >
          {error}
        </motion.p>
      )}
    </div>
  );
};

export default WhatsAppInput;