import React, { createContext, useContext, useReducer } from 'react';
import TEST_CONFIG from '../../config/test.config';
import questionService from '../../services/questionService';

const DIFFICULTY_CONFIG = {
  MIN_LEVEL: 1,
  MAX_LEVEL: 5,
  INITIAL_LEVEL: 3,
  PERFORMANCE_THRESHOLD: 0.5
};

const initialState = {
  currentQuestion: null,
  selectedAnswer: '',
  questionStartTime: Date.now(),
  currentQuestionTime: 0,
  totalTimeRemaining: TEST_CONFIG.TEST_TIME_SECONDS,
  currentQuestionIndex: 0,
  answers: [],
  isLoading: false,
  error: null,
  currentDifficulty: DIFFICULTY_CONFIG.INITIAL_LEVEL,
  usedQuestionIds: new Set(),
  questionsByDifficulty: {
    level_1: [],
    level_2: [],
    level_3: [],
    level_4: [],
    level_5: []
  },
  allQuestions: [],
  isTestComplete: false,
  studentGrade: null,
  testConfig: {
    questionsPerLevel: TEST_CONFIG.TOTAL_QUESTIONS,
    totalQuestions: TEST_CONFIG.TOTAL_QUESTIONS,
    timeLimit: TEST_CONFIG.TEST_TIME_SECONDS,
    distribution: TEST_CONFIG.getQuestionDistribution()
  }
};

const ACTION_TYPES = {
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_QUESTIONS: 'SET_QUESTIONS',
  SET_CURRENT_QUESTION: 'SET_CURRENT_QUESTION',
  SET_SELECTED_ANSWER: 'SET_SELECTED_ANSWER',
  UPDATE_TIME: 'UPDATE_TIME',
  ADD_ANSWER: 'ADD_ANSWER',
  SET_NEXT_QUESTION: 'SET_NEXT_QUESTION',
  SET_STUDENT_GRADE: 'SET_STUDENT_GRADE',
  UPDATE_DIFFICULTY: 'UPDATE_DIFFICULTY',
  SET_TEST_COMPLETE: 'SET_TEST_COMPLETE',
  RESET_STATE: 'RESET_STATE'
};

const calculateNextDifficulty = (currentDifficulty, performance) => {
  const isCorrect = performance >= DIFFICULTY_CONFIG.PERFORMANCE_THRESHOLD;
  
  const nextDifficulty = isCorrect ? 
    Math.min(currentDifficulty + 1, DIFFICULTY_CONFIG.MAX_LEVEL) : 
    Math.max(currentDifficulty - 1, DIFFICULTY_CONFIG.MIN_LEVEL);

  console.log('Calculating next difficulty:', {
    current: currentDifficulty,
    next: nextDifficulty,
    performance,
    isCorrect,
    change: nextDifficulty - currentDifficulty
  });

  return nextDifficulty;
};

const calculateProgress = (questionIndex, questionsPerLevel) => ({
  currentLevel: Math.floor(questionIndex / questionsPerLevel) + 1,
  questionsInLevel: (questionIndex % questionsPerLevel) + 1,
  isLastInLevel: (questionIndex + 1) % questionsPerLevel === 0
});

function questionReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING:
      return { 
        ...state, 
        isLoading: action.payload,
        error: action.payload ? null : state.error 
      };

    case ACTION_TYPES.SET_ERROR:
      return { 
        ...state, 
        error: action.payload, 
        isLoading: false 
      };

    case ACTION_TYPES.SET_QUESTIONS:
      if (!action.payload?.questions) {
        return {
          ...state,
          isLoading: false,
          error: 'No questions available'
        };
      }

      // Extract questions for each difficulty level
      const questionsByLevel = {
        level_1: action.payload.questions.level_1 || [],
        level_2: action.payload.questions.level_2 || [],
        level_3: action.payload.questions.level_3 || [],
        level_4: action.payload.questions.level_4 || [],
        level_5: action.payload.questions.level_5 || []
      };

      // Get first question from initial difficulty level
      const firstQuestion = questionsByLevel[`level_${state.currentDifficulty}`][0];

      if (!firstQuestion) {
        return {
          ...state,
          isLoading: false,
          error: 'No questions available for initial difficulty level'
        };
      }

      return {
        ...state,
        questionsByDifficulty: questionsByLevel,
        allQuestions: Object.values(questionsByLevel).flat(),
        currentQuestion: firstQuestion,
        usedQuestionIds: new Set([firstQuestion._id]),
        isLoading: false,
        error: null
      };

    case ACTION_TYPES.SET_STUDENT_GRADE:
      return {
        ...state,
        studentGrade: action.payload
      };

    case ACTION_TYPES.SET_SELECTED_ANSWER:
      return {
        ...state,
        selectedAnswer: action.payload
      };

    case ACTION_TYPES.UPDATE_TIME:
      return {
        ...state,
        currentQuestionTime: action.payload.currentQuestionTime,
        totalTimeRemaining: action.payload.totalTimeRemaining
      };

    case ACTION_TYPES.ADD_ANSWER:
      return {
        ...state,
        answers: [...state.answers, action.payload]
      };

    case ACTION_TYPES.SET_NEXT_QUESTION:
      if (state.currentQuestionIndex >= state.testConfig.totalQuestions - 1) {
        return { 
          ...state, 
          isTestComplete: true 
        };
      }

      if (!action.payload?.question) {
        return {
          ...state,
          error: 'No next question available',
          isLoading: false
        };
      }

      return {
        ...state,
        currentQuestion: action.payload.question,
        currentDifficulty: action.payload.difficulty,
        currentQuestionIndex: state.currentQuestionIndex + 1,
        usedQuestionIds: new Set([
          ...state.usedQuestionIds,
          action.payload.question._id
        ]),
        selectedAnswer: '',
        questionStartTime: Date.now(),
        isLoading: false,
        error: null
      };

    case ACTION_TYPES.UPDATE_DIFFICULTY:
      return {
        ...state,
        currentDifficulty: Math.max(
          DIFFICULTY_CONFIG.MIN_LEVEL,
          Math.min(DIFFICULTY_CONFIG.MAX_LEVEL, action.payload)
        )
      };

    case ACTION_TYPES.SET_TEST_COMPLETE:
      return {
        ...state,
        isTestComplete: true
      };

    case ACTION_TYPES.RESET_STATE:
      return {
        ...initialState,
        questionStartTime: Date.now(),
        studentGrade: state.studentGrade,
        testConfig: state.testConfig
      };

    default:
      return state;
  }
}

export const QuestionContext = createContext();

export function QuestionProvider({ children }) {
  const [state, dispatch] = useReducer(questionReducer, {
    ...initialState,
    questionStartTime: Date.now()
  });

  const actions = React.useMemo(() => ({
    setLoading: (isLoading) => 
      dispatch({ type: ACTION_TYPES.SET_LOADING, payload: isLoading }),
      
    setError: (error) => 
      dispatch({ type: ACTION_TYPES.SET_ERROR, payload: error }),
      
    setQuestions: async (grade) => {
      if (state.isLoading || (state.allQuestions.length > 0 && state.studentGrade === grade)) {
        console.log('Skipping duplicate questions fetch');
        return;
      }

      try {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
        const data = await questionService.fetchQuestions(grade);
        dispatch({ type: ACTION_TYPES.SET_QUESTIONS, payload: data });
      } catch (error) {
        console.error('Error loading questions:', error);
        dispatch({ 
          type: ACTION_TYPES.SET_ERROR, 
          payload: error.message || 'Failed to load questions'
        });
      } finally {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
      }
    },
      
    setSelectedAnswer: (answer) => 
      dispatch({ type: ACTION_TYPES.SET_SELECTED_ANSWER, payload: answer }),
      
    updateTime: (currentQuestionTime, totalTimeRemaining) => 
      dispatch({
        type: ACTION_TYPES.UPDATE_TIME,
        payload: { currentQuestionTime, totalTimeRemaining }
      }),
      
    addAnswer: (answer) => 
      dispatch({ type: ACTION_TYPES.ADD_ANSWER, payload: answer }),
      
    setNextQuestion: async (response, performance) => {
      if (state.isLoading) return;
    
      try {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
        
        const nextDifficulty = calculateNextDifficulty(
          state.currentDifficulty,
          performance
        );
    
        // Get available questions for next difficulty level
        const availableQuestions = state.questionsByDifficulty[`level_${nextDifficulty}`]
          .filter(q => !state.usedQuestionIds.has(q._id));
    
        let nextQuestion;
        if (availableQuestions.length > 0) {
          // Get a random question from available questions
          const randomIndex = Math.floor(Math.random() * availableQuestions.length);
          nextQuestion = availableQuestions[randomIndex];
        } else {
          // If no questions available locally, fetch from server
          const data = await questionService.fetchNextAdaptiveQuestion({
            currentDifficulty: nextDifficulty,
            excludeIds: Array.from(state.usedQuestionIds),
            grade: state.studentGrade
          });
          nextQuestion = data.question;
        }
    
        console.log('Question transition:', {
          currentIndex: state.currentQuestionIndex,
          currentDifficulty: state.currentDifficulty,
          nextDifficulty,
          performance,
          availableQuestionsCount: availableQuestions?.length || 0
        });
    
        dispatch({
          type: ACTION_TYPES.SET_NEXT_QUESTION,
          payload: {
            question: nextQuestion,
            difficulty: nextDifficulty
          }
        });
      } catch (error) {
        console.error('Error setting next question:', error);
        dispatch({ 
          type: ACTION_TYPES.SET_ERROR, 
          payload: error.message || 'Failed to load next question'
        });
      } finally {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
      }
    },
      
    setStudentGrade: (grade, shouldFetchQuestions = true) => {
      if (!grade) return;
      
      dispatch({ type: ACTION_TYPES.SET_STUDENT_GRADE, payload: grade });
      
      if (shouldFetchQuestions && grade !== state.studentGrade) {
        actions.setQuestions(grade);
      }
    },

    updateDifficulty: (newDifficulty) =>
      dispatch({ type: ACTION_TYPES.UPDATE_DIFFICULTY, payload: newDifficulty }),

    setTestComplete: () =>
      dispatch({ type: ACTION_TYPES.SET_TEST_COMPLETE }),

    resetState: () => 
      dispatch({ type: ACTION_TYPES.RESET_STATE }),

    calculatePerformance: (answer) => {
      return answer.correct ? 1 : 0;
    },

    getProgress: () => calculateProgress(
      state.currentQuestionIndex,
      state.testConfig.questionsPerLevel
    )
  }), [state.isLoading, state.studentGrade, state.currentDifficulty, 
       state.usedQuestionIds, state.allQuestions, state.currentQuestionIndex,
       state.testConfig.questionsPerLevel]);

  const value = React.useMemo(() => ({
    state,
    dispatch,
    actions
  }), [state, actions]);

  return (
    <QuestionContext.Provider value={value}>
      {children}
    </QuestionContext.Provider>
  );
}

export function useQuestion() {
  const context = useContext(QuestionContext);
  if (!context) {
    throw new Error('useQuestion must be used within a QuestionProvider');
  }
  return context;
}

export { ACTION_TYPES, DIFFICULTY_CONFIG };