// src/components/results/performance/PerformanceHeader/PerformanceTemplates.js
import {
    StarIcon,
    TrophyIcon,
    RocketLaunchIcon,
    ChartBarIcon,
    BookOpenIcon,
    ClockIcon,
    LightBulbIcon,
    AcademicCapIcon,
    CheckCircleIcon,
  } from '@heroicons/react/24/outline';
  
  export const performanceTemplates = {
    PERFECT: {
      icon: TrophyIcon,
      color: 'text-yellow-600',
      bgColor: 'bg-yellow-50',
      gradient: 'from-yellow-500 to-amber-600',
      title: 'Outstanding Achievement! 🏆',
      subtitle: 'Perfect Score: 100%',
      message: 'Exceptional performance! You\'ve mastered all concepts.',
      confetti: true,
      badges: [
        { text: 'Perfect Score', icon: StarIcon },
        { text: 'Master Level', icon: AcademicCapIcon },
        { text: 'Complete Understanding', icon: CheckCircleIcon }
      ],
      recommendations: [
        { text: 'Take advanced challenges', icon: ChartBarIcon },
        { text: 'Help others learn', icon: TrophyIcon },
        { text: 'Explore new topics', icon: RocketLaunchIcon }
      ]
    },
    EXCELLENT: {
        icon: StarIcon,
        color: 'text-blue-600',
        bgColor: 'bg-blue-50',
        gradient: 'from-blue-500 to-indigo-600',
        title: 'Excellent Performance! ⭐',
        subtitle: 'High Score: 80-99%',
        message: 'Great job! You\'ve shown strong understanding.',
        confetti: false,
        badges: [
          { text: 'High Achiever', icon: StarIcon },
          { text: 'Strong Performance', icon: RocketLaunchIcon },
          { text: 'Great Understanding', icon: CheckCircleIcon }
        ],
        recommendations: [
          { text: 'Focus on missed concepts', icon: LightBulbIcon },
          { text: 'Practice similar questions', icon: ChartBarIcon },
          { text: 'Aim for perfection', icon: StarIcon }
        ]
      },
      IMPROVING: {
        icon: RocketLaunchIcon,
        color: 'text-purple-600',
        bgColor: 'bg-purple-50',
        gradient: 'from-purple-500 to-purple-600',
        title: 'Keep Growing! 🚀',
        subtitle: 'Score: Below 80%',
        message: 'Good effort! Let\'s work on improvement.',
        confetti: false,
        badges: [
          { text: 'Making Progress', icon: ChartBarIcon },
          { text: 'Building Skills', icon: RocketLaunchIcon },
          { text: 'Growing Understanding', icon: LightBulbIcon }
        ],
        recommendations: [
          { text: 'Review core concepts', icon: BookOpenIcon },
          { text: 'Practice regularly', icon: ClockIcon },
          { text: 'Focus on fundamentals', icon: AcademicCapIcon }
        ]
      }
  };
  
  export const getPerformanceLevel = (score) => {
    if (score === 100) return 'PERFECT';
    if (score >= 80) return 'EXCELLENT';
    return 'IMPROVING';
  };