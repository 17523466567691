// src/components/test/QuestionDisplay/components/QuestionHeader/QuestionHeader.jsx
import React, { useMemo } from 'react';
import Timer from './Timer';
import { ProgressBar } from './ProgressBar';

const DifficultyBadge = ({ level, grade }) => {
  const difficultyInfo = useMemo(() => {
    // Convert grade to number and check if it's junior grade
    const gradeNum = parseInt(grade?.toString().replace(/\D/g, '') || '0');
    const isJunior = gradeNum <= 6;

    // Adjust difficulty display for junior grades
    const adjustedLevel = level;

    const getColor = () => {
      if (adjustedLevel === 1) return {
        bg: 'bg-blue-100',
        text: 'text-blue-800',
        ring: 'ring-blue-500/30',
        label: 'Level 1'
      };
      if (adjustedLevel === 2) return {
        bg: 'bg-green-100',
        text: 'text-green-800',
        ring: 'ring-green-500/30',
        label: 'Level 2'
      };
      if (adjustedLevel === 3) return {
        bg: 'bg-yellow-100',
        text: 'text-yellow-800',
        ring: 'ring-yellow-500/30',
        label: 'Level 3'
      };
      if (adjustedLevel === 4) return {
        bg: 'bg-orange-100',
        text: 'text-orange-800',
        ring: 'ring-orange-500/30',
        label: 'Level 4'
      };
      if (adjustedLevel === 5) return {
        bg: 'bg-red-100',
        text: 'text-red-800',
        ring: 'ring-red-500/30',
        label: 'Level 5'
      };
    };

    return {
      colors: getColor(),
      gradeDisplay: gradeNum || '',
      isJunior
    };
  }, [level, grade]);

  return (
    <div className={`
      inline-flex items-center gap-1.5 px-2.5 py-1 
      rounded-full text-xs font-semibold ring-1 ring-inset
      ${difficultyInfo.colors.bg} ${difficultyInfo.colors.text} ${difficultyInfo.colors.ring}
    `}>
      <svg className="w-3.5 h-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
      </svg>
      <span>{difficultyInfo.colors.label}</span>
      {!difficultyInfo.isJunior && <span className="opacity-75"></span>}
    </div>
  );
};

const QuestionCounter = ({ current, total }) => (
  <div className="flex items-center gap-2">
    <span className="text-lg font-bold text-purple-600">
      {current}
    </span>
    <span className="text-sm text-gray-500">
      of {total} Questions
    </span>
  </div>
);

const ProgressIndicator = ({ progress, showLabel = true }) => (
  <div className="space-y-1.5">
    {showLabel && (
      <div className="flex justify-between text-xs text-gray-600">
        <span>Overall Progress</span>
        <span>{Math.round(progress)}%</span>
      </div>
    )}
    <ProgressBar 
      progress={progress} 
      className="h-1.5" 
    />
  </div>
);

const StudentInfo = ({ name, grade }) => {
  const gradeDisplay = useMemo(() => {
    if (!grade) return '';
    // Remove non-digit characters and convert to number
    const gradeNum = grade.toString().replace(/\D/g, '');
    return `Grade ${gradeNum}`;
  }, [grade]);

  return (
    <div className="flex items-center gap-2">
      {/* <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center">
        <span className="text-sm font-medium text-purple-700">
          {name?.[0]?.toUpperCase()}
        </span>
      </div> */}
      <div className="flex flex-col">
        {/* <span className="text-sm font-medium text-gray-900">{name}</span> */}
        {/* <span className="text-xs text-gray-500">{gradeDisplay}</span> */}
      </div>
    </div>
  );
};

const QuestionHeader = ({ 
  questionNumber, 
  totalQuestions, 
  currentDifficulty,
  timeRemaining,
  progress,
  studentName,
  studentGrade,
  isMobile = false
}) => {
  const mobileHeader = (
    <div className="sticky top-0 z-20 bg-white shadow-sm">
      <div className="px-4 py-3 border-b border-gray-100">
        <div className="flex items-center justify-between mb-3">
          <Timer 
            timeRemaining={timeRemaining}
            variant="compact"
            grade={studentGrade}
          />
        </div>
        
        <ProgressIndicator progress={progress} />
      </div>

      <div className="px-4 py-2 bg-gray-50/80 backdrop-blur-sm">
        <div className="flex items-center justify-between">
          <DifficultyBadge 
            level={currentDifficulty} 
            grade={studentGrade}
          />
          <StudentInfo 
            name={studentName} 
            grade={studentGrade}
          />
        </div>
      </div>
    </div>
  );

  const desktopHeader = (
    <div className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-6">
            <div className="h-4 w-px bg-gray-200" />
            <DifficultyBadge 
              level={currentDifficulty} 
              grade={studentGrade}
            />
          </div>

          <div className="flex items-center gap-6">
            <StudentInfo 
              name={studentName} 
              grade={studentGrade}
            />
            <Timer 
              timeRemaining={timeRemaining}
              grade={studentGrade}
              className="min-w-[140px]"
            />
          </div>
        </div>
      </div>

      <div className="h-1 bg-gray-100">
        <div 
          className="h-full bg-purple-600 transition-all duration-300"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );

  return isMobile ? mobileHeader : desktopHeader;
};

export default React.memo(QuestionHeader);