import { useEffect } from 'react';
import { useQuestion } from '../components/test/QuestionContext';

export const useQuestionTimer = () => {
  const { state, actions } = useQuestion();

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = Date.now();
      const questionTime = Math.floor((currentTime - state.questionStartTime) / 1000);
      const newTimeRemaining = Math.max(0, state.totalTimeRemaining - 1);
      
      // Update the time
      actions.updateTime(questionTime, newTimeRemaining);

      // Check if time has run out
      if (newTimeRemaining === 0) {
        clearInterval(timer);
        handleTestTimeout();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [state.questionStartTime, state.totalTimeRemaining]);

  const handleTestTimeout = async () => {
    try {
      const testId = sessionStorage.getItem('testId');
      const studentInfo = {
        name: sessionStorage.getItem('studentName'),
        grade: sessionStorage.getItem('studentGrade'),
        whatsapp: sessionStorage.getItem('studentWhatsapp'),
        email: sessionStorage.getItem('studentEmail')
      };

      // Mark current question as unattempted if not answered
      if (!state.selectedAnswer) {
        const timeTaken = ((Date.now() - state.questionStartTime) / 1000).toFixed(1);
        
        // Submit the current question as unattempted
        await fetch(`${import.meta.env.VITE_API_URL}/responses`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            test_id: testId,
            student_id: studentInfo.name,
            student_info: studentInfo,
            question_id: state.currentQuestion._id,
            student_answer: 'Unattempted',
            correct: false,
            time_taken: parseFloat(timeTaken),
            time_stamp: new Date().toISOString(),
            difficulty_level: state.currentDifficulty
          }),
        });
      }

      // End the test
      const completionData = {
        test_id: testId,
        student_id: studentInfo.name,
        student_info: studentInfo,
        answers: state.answers,
        unattemptedQuestions: [],
        timeoutEnded: true
      };

      await fetch(`${import.meta.env.VITE_API_URL}/end-test`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(completionData),
      });

      // Set test as complete and redirect to results
      actions.setTestComplete(true);
      window.location.href = `/results/${testId}`;
    } catch (error) {
      console.error('Error handling test timeout:', error);
      actions.setError('Test timed out, but failed to submit results');
    }
  };

  return {
    currentQuestionTime: state.currentQuestionTime,
    totalTimeRemaining: state.totalTimeRemaining,
    isTimeUp: state.totalTimeRemaining === 0
  };
};

export default useQuestionTimer;