// client/src/config/test.config.js

// Get values from environment variables or use defaults
const TEST_CONFIG = {
    TOTAL_QUESTIONS: parseInt(import.meta.env.VITE_TOTAL_QUESTIONS || '20', 10),
    TEST_TIME_MINUTES: parseInt(import.meta.env.VITE_TEST_TIME_MINUTES || '20', 10),
    
    // Derived values
    TEST_TIME_SECONDS: parseInt(import.meta.env.VITE_TEST_TIME_MINUTES || '20', 10) * 60,
    
    // Question distribution (for different difficulty levels)
    getQuestionDistribution() {
      const total = this.TOTAL_QUESTIONS;
      return {
        level1: total,
        level2: total,
        level3: total,
        level4: total,
        level5: total
      };
    },
  
    // Validation
    validateConfig() {
      if (this.TOTAL_QUESTIONS < 1) {
        throw new Error('TOTAL_QUESTIONS must be at least 1');
      }
      if (this.TEST_TIME_MINUTES < 1) {
        throw new Error('TEST_TIME_MINUTES must be at least 1');
      }
    }
  };
  
  // Validate configuration on import
  TEST_CONFIG.validateConfig();
  
  export default TEST_CONFIG;